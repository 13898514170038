import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useTheme } from "@material-ui/styles";
import moment from 'moment'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

// components
import Widget from "../../components/Widget/Widget";
import ApexLineChart from "./components/ApexLineChart";
import PageTitle from "../../components/PageTitle/PageTitle";
import DashboardIcon from '../../assets/icons/dashboard.svg';
import { dashboardLastMonthsAcordos, dashboardUsersAcordos, getDashboardLineDaily } from "../../services/DashboardService";

export default function Dashboard(props) {
  const { children } = props;
  var theme = useTheme();
  const [lastMonths, setLastMonths] = useState([]);
  const [competencia, setCompetencia] = useState(moment().format('MM/Y'));
  const [dataApexLineChartDaily, setDataApexLineChartDaily] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [lineChartData, setLineChartData] = useState({
    keys: [],
    data: []
  });

  useEffect(() => {
    moment.locale();
    getLastMonths();
    getDataChartLineDaily();
    getDataPieChart();
    getLineChartData();
  }, [])


  const getDataChartLineDaily = (value = null) => {
    children(true);
    const comp = value ? value : competencia;
    setCompetencia(comp);
    const year = comp.substring(3,7);
    const month = comp.substring(0,2);
    const formatedComp = year + '-' + month;   
    getDashboardLineDaily(formatedComp).then(response => {
      if(response.data){
        setDataApexLineChartDaily(response.data);
        children(false);
      }
    }).catch(error => {
      console.log(error);
      children(false);
    });
  }

  const getDataPieChart = () => {
    children(true);
    dashboardUsersAcordos().then(
      response => {
        if(response.data){
          setPieChartData(response.data);
          children(false);
        }
      }
    ).catch(error => {
      console.log(error);
      children(false);
    })
  }

  const getLineChartData = () => {
    children(true);
    dashboardLastMonthsAcordos().then(
      response => {
        if(response.data) {
          setLineChartData(response.data);
        }
      }
    ).catch(
      error => {
        console.log(error);
        children(false);
      }
    )

  }

  const getLastMonths = () => {
    const arrayMonths = [];
    for(var i = 0; i < 12; i++){
      arrayMonths.push(moment().subtract(i, 'months').format('MM/Y'));
    }
    setLastMonths(arrayMonths);
    setCompetencia(moment().format('MM/Y'))
  }

  // local
  var [activeIndex, setActiveIndexId] = useState(0);

  return (
    <>
      <PageTitle title="Dashboard DS Cobranças" icon={DashboardIcon} />
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Widget title={`Acordos diários referente a competência ${competencia}`} disableWidgetMenu  >
            <FormControl variant="outlined" style={{width: "50%", marginTop: -30, float:"right"}} >
              <InputLabel id="demo-simple-select-outlined-label">Competência</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={competencia}
                onChange={e => getDataChartLineDaily(e.target.value)}
                label="Competência"
              >
                {
                  lastMonths.map(item => (
                    <MenuItem key={item} value={item}>{item}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>

            <ResponsiveContainer >
              <div style={{marginTop: 60}}>
              <ApexLineChart data={dataApexLineChartDaily} />
              </div>  
            </ResponsiveContainer>
           

          </Widget>
        </Grid>
        <Grid item xs={12} md={7}>
          <Widget title="Acordo dos ultimos meses" noBodyPadding upperTitle disableWidgetMenu>
            <ResponsiveContainer width="100%" height={350}>
              <LineChart
                width={500}
                height={300}
                data={lineChartData.data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="comp" />
                <YAxis />
                <Tooltip />
                <Legend />
                {
                    lineChartData.keys.map(key => (
                      <Line
                        type="monotone"
                        dataKey={key}
                        stroke={theme.palette.primary.main}
                        activeDot={{ r: 8 }}
                      />
                    ))
                }
                {/* <Line
                  type="monotone"
                  dataKey="Reinaldo"
                  stroke={theme.palette.secondary.main}
                /> */}
              </LineChart>
            </ResponsiveContainer>
          </Widget>
        </Grid>
        <Grid item xs={12} md={5}>
          <Widget title="Total de acordos por usuário" noBodyPadding upperTitle disableWidgetMenu>
            <ResponsiveContainer s width="100%" height={300}>
              <PieChart width={350} height={300}>
                <Pie
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  data={pieChartData}
                  cx={250}
                  cy={150}
                  innerRadius={60}
                  outerRadius={80}
                  fill={theme.palette.primary.main}
                  dataKey="value"
                  onMouseEnter={(e, id) => setActiveIndexId(id)}
                />
              </PieChart>
            </ResponsiveContainer>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}

// ################################################################

function renderActiveShape(props) {
  var RADIAN = Math.PI / 180;
  var {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  var sin = Math.sin(-RADIAN * midAngle);
  var cos = Math.cos(-RADIAN * midAngle);
  var sx = cx + (outerRadius + 10) * cos;
  var sy = cy + (outerRadius + 10) * sin;
  var mx = cx + (outerRadius + 30) * cos;
  var my = cy + (outerRadius + 30) * sin;
  var ex = mx + (cos >= 0 ? 1 : -1) * 22;
  var ey = my;
  var textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`Acordos:  ${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(Perc. ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
}
