import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button, Grid, TextField } from '@material-ui/core';
import { formatCPF, formatTelefone } from '../../../shared/Utils';
import { patchCliente } from '../../../services/ClienteService';
import { toast } from 'react-toastify';
import MaskedInput from 'react-text-mask';
import MaskInputs from '../../../components/Inputs/MaskInputs';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/,'.',/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

export default function DadosFicha(props) {

  const { cliente, getDadosCliente } = props;

  const classes = useStyles();

  const [accordionDadosDevedor, setAccordionDadosDevedor] = useState(true);
  const [accordionLocalTrab, setAccordionLocalTrab] = useState(true);

  const [dataCliente, setDataCliente] = useState({});

  useEffect(() => {
      setDataCliente( {
        nome_cliente: cliente.nome_cliente,
        telefone: cliente.telefone,
        cpf: cliente.cpf,
        identidade: cliente.identidade,
        endereco: cliente.endereco,
        observacao: cliente.observacao
        })
  }, [cliente]);


  const changeValue = (campo, event) => {
        setDataCliente({ ...dataCliente, [campo]: event.target.value })
  }

  const updateDadosFicha = () => {
      patchCliente(cliente.id_cliente, dataCliente).then(response => {
          if(response.data.status){
            toast.success("Dados alterados com sucesso !");
            getDadosCliente();
          }
      }).catch(error => {
          console.log(error);
          toast.error("Não foi possível alterar os dados, entre em contato com o Reinaldo !");
      })
  }

  return (
      
    <div className={classes.root}>

      <Accordion
      expanded={accordionDadosDevedor}
      onChange={() => setAccordionDadosDevedor(!accordionDadosDevedor)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}><b>Dados do Devedor</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}  md={3}>
                    <TextField 
                        fullWidth 
                        id="codigo_cliente" 
                        label="Código Cliente" 
                        InputProps={{
                            readOnly: true,
                        }}
                        defaultValue=" " 
                        value={cliente.codigo_cliente}
                        variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={12}  md={6}>
                    <TextField 
                        fullWidth id="nome_cliente" 
                        label="Nome" 
                        defaultValue={" "}
                        onChange={(e) => changeValue("nome_cliente", e) }
                        defaultValue={" "}
                        value={dataCliente.nome_cliente}
                        variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={12} md={3} >
                    <TextField 
                        fullWidth id="cpf" 
                        label="CPF" 
                        onChange={(e) => changeValue("cpf", e) }
                        defaultValue={" "}
                        value={dataCliente.cpf} 
                        InputProps={{
                            inputComponent: TextMaskCustom,
                        }}
                        variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <TextField
                        fullWidth 
                        variant="outlined"
                        id="date"
                        readOnly
                        label="Data do Cadastro"
                        type="date"
                        value={cliente.created_at}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
               
                <Grid item xs={12} sm={12} md={3}>
                    <TextField 
                        fullWidth 
                        id="identidade" 
                        label="Identidade" 
                        onChange={(e) => changeValue("identidade", e) }
                        defaultValue=" " 
                        value={dataCliente.identidade} 
                        variant="outlined" />
                </Grid>

                <Grid item xs={12} sm={12} md={3}>
                    <TextField 
                        fullWidth 
                        id="telefone" 
                        label="Telefone" 
                        onChange={(e) => changeValue("telefone", e) }
                        defaultValue={" "}
                        value={dataCliente.telefone} 
                        InputProps={{
                            inputComponent: MaskInputs,
                            type: 'telefone'
                        }}
                        variant="outlined" />
                </Grid>

                <Grid item xs={12} sm={12} md={3} >
                    <TextField
                        fullWidth 
                        variant="outlined"
                        id="dt_nascimento"
                        readOnly
                        label="Data de Nascimento"
                        type="date"
                        value={cliente.dt_nascimento}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                    <TextField
                        fullWidth 
                        variant="outlined"
                        id="cep"
                        label="CEP"
                        onChange={(e) => changeValue("cep", e) }
                        defaultValue={" "}
                        value={cliente.cep}
                        InputLabelProps={{
                            readOnly: true,
                        }}
                        />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <TextField
                        fullWidth 
                        variant="outlined"
                        id="endereco"
                        label="Endereço"
                        onChange={(e) => changeValue("endereco", e) }
                        defaultValue={" "}
                        value={cliente.endereco}
                        />
                </Grid>

                <Grid item xs={12} sm={12} md={3}>
                    <TextField
                        fullWidth 
                        variant="outlined"
                        id="bairro"
                        label="Bairro"
                        onChange={(e) => changeValue("bairro", e) }
                        defaultValue={" "}
                        value={cliente.bairro}
                        InputLabelProps={{
                            readOnly: true,
                        }}
                        />
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                    <TextField
                        fullWidth 
                        variant="outlined"
                        id="cidade"
                        label="Cidade"
                        onChange={(e) => changeValue("cidade", e) }
                        defaultValue={" "}
                        value={cliente.cidade}
                        InputLabelProps={{
                            readOnly: true,
                        }}
                        />
                </Grid>

                <Grid item xs={12} sm={12} md={1}>
                    <TextField
                        fullWidth 
                        variant="outlined"
                        id="estado"
                        label="Estado"
                        onChange={(e) => changeValue("estado", e) }
                        defaultValue={" "}
                        value={cliente.estado}
                        InputLabelProps={{
                            readOnly: true,
                        }}
                        />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        fullWidth 
                        variant="outlined"
                        id="observacao"
                        label="Observacao"
                        rows={3}
                        multiline={true}
                        onChange={(e) => changeValue("observacao", e) }
                        defaultValue={" "}
                        value={dataCliente.observacao}
                        />
                </Grid>

                <Grid item xs={12}>
                    <Button onClick={() => updateDadosFicha()} style={{float: "right"}} variant="outlined">Alterar Dados</Button>
                </Grid>

              
            </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
       expanded={accordionLocalTrab}
       onChange={() => setAccordionLocalTrab(!accordionLocalTrab)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}><b>Local de Trabalho</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={4}>
                    <TextField
                        fullWidth 
                        variant="outlined"
                        id="empresa"
                        label="Firma"
                        InputProps={{
                            readOnly: true,
                        }}
                        defaultValue={" "}
                        value={cliente.nome_empresa}
                        />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <TextField
                        fullWidth 
                        variant="outlined"
                        id="telefone_empresa"
                        label="Telefone"
                        InputProps={{
                            readOnly: true,
                        }}
                        defaultValue={" "}
                        value={formatTelefone(cliente.telefone_empresa)}
                        />
                </Grid>

                <Grid item xs={12} sm={12} md={5}>
                    <TextField
                        fullWidth 
                        variant="outlined"
                        id="endereco_empresa"
                        label="Endereço"
                        InputProps={{
                            readOnly: true,
                        }}
                        defaultValue={" "}
                        value={cliente.endereco_empresa}
                        />
                </Grid>

                <Grid item xs={12} sm={12} md={3}>
                    <TextField
                        fullWidth 
                        variant="outlined"
                        id="cidade_empresa"
                        label="Cidade"
                        InputProps={{
                            readOnly: true,
                        }}
                        defaultValue={" "}
                        value={cliente.cidade_empresa}
                        />
                </Grid>

                <Grid item xs={12} sm={12} md={1}>
                    <TextField
                        fullWidth 
                        variant="outlined"
                        id="estado_empresa"
                        label="Estado"
                        InputProps={{
                            readOnly: true,
                        }}
                        defaultValue={" "}
                        value={cliente.estado_empresa}
                        />
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                    <TextField
                        fullWidth 
                        variant="outlined"
                        id="referencia1"
                        label="Refer. 1"
                        InputProps={{
                            readOnly: true,
                        }}
                        defaultValue={" "}
                        value={cliente.referencia1}
                        />
                </Grid>

                
                <Grid item xs={12} sm={12} md={4}>
                    <TextField
                        fullWidth 
                        variant="outlined"
                        id="referencia2"
                        label="Refer. 2"
                        InputProps={{
                            readOnly: true,
                        }}
                        defaultValue={" "}
                        value={cliente.referencia2}
                        />
                </Grid>
            </Grid>
        </AccordionDetails>
      </Accordion>
     
    </div>
  );
}