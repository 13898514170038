import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import moment from 'moment';
import { DialogContent, Grid } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { getClientsRecibo } from '../../../services/RelatorioService';
import { toast } from 'react-toastify';
import { getDataClient } from '../../../services/ClienteService';
import Recibo from './Recibo';
import PrintIcon from '@material-ui/icons/Print';
import { print } from '../../../shared/Utils';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ListaRecibo(props) {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(moment().subtract(7, 'day').format('YYYY-MM-DD'));
  const [finalDate, setFinalDate] = useState(moment().format('YYYY-MM-DD'));
  const [dataClientes, setDataClientes] = useState([]);

  const { handleClose, loading } = props;

  useEffect(() => {
    loadRecibos();
  }, []);

  const loadRecibos = () => {
    loading(true);
    getClientsRecibo(moment(startDate).format('YYYY-MM-DD'), moment(finalDate).format('YYYY-MM-DD'))
      .then(response => {
        const data = response.data.data;
        if (data) {
          const newDataClientes = [];
          data.forEach(item => {
            getDataClient(item.id_cliente)
              .then(response => {
                const clienteData = response.data.data;
                newDataClientes.push(clienteData);
              })
              .catch(error => {
                toast.error('Ocorreu um erro, não foi possível carregar os recibos, entre em contato com o Reinaldo !');
                console.log(error);
              });
          });
          setTimeout(() => {
            loading(false);
          }, data.length * 700);
          setDataClientes(newDataClientes);
        }
      })
      .catch(error => {
        toast.error('Ocorreu um erro, não foi possível carregar os recibos, entre em contato com o Reinaldo !');
        console.log(error);
        loading(false);
      });
  };

  const removeItemRecibo = id_cliente => {
    loading(true);
    const newDataClientes = [];
    dataClientes.map(cliente => {
      if (cliente.id_cliente != id_cliente) {
        newDataClientes.push(cliente);
      }
    });
    setDataClientes(newDataClientes);
    setTimeout(() => {
      loading(false);
    }, 300);
  };

  return (
    <div>
      <Dialog fullScreen open onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Período {moment(startDate).format('DD/MM/YYYY')} à {moment(finalDate).format('DD/MM/YYYY')}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Fechar
            </Button>
          </Toolbar>
        </AppBar>

        <DialogContent style={{ padding: 20, overflowX: 'hidden' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  id="date-picker-inline"
                  label="Data Inicial"
                  required
                  value={moment(startDate)}
                  onChange={value => setStartDate(value)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  id="date-picker-inline"
                  label="Data Final"
                  required
                  value={moment(finalDate)}
                  onChange={value => setFinalDate(value)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Button onClick={loadRecibos} size="large" variant="contained" color="primary">
                Filtrar
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button onClick={() => print('list-recibos')} style={{ marginLeft: 10 }} size="large" variant="contained" color="primary" startIcon={<PrintIcon />}>
                Imprimir
              </Button>
            </Grid>
          </Grid>

          <div style={{ marginTop: 20 }}>
            <Grid id="list-recibos" container spacing={2}>
              {dataClientes.map(cliente => (
                <Grid key={cliente.id_cliente} item xs={12} sm={6} md={4}>
                  <div style={{ display: 'flex', flex: '1', flexWrap: 'wrap' }}>
                    <Button onClick={() => removeItemRecibo(cliente.id_cliente)} style={{ marginLeft: '3%' }} color="secondary" className={'remove-recibo'} startIcon={<DeleteIcon />}>
                      Remover
                    </Button>
                    <Recibo cliente={cliente} data_inicial={startDate} data_final={finalDate} />
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
