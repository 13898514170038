import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { calculoJuros, formatDate, getDiasVencidos, getValorFinal } from '../../../shared/Utils';
import AlteraAcordo from '../../calendar/components/AlteraAcordo';
import { Button, Checkbox, TextField } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { baixaParcelas, removerBaixaParcela, updateParcela } from '../../../services/ParcelaService';
import MySweetAlert from '../../../components/SweetAlert';
import { toast } from 'react-toastify';
import { getDataClient } from '../../../services/ClienteService';
import CurrencyInput from '../../../components/Inputs/CurrencyInput';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function createData(loja, numero_documento, dt_emissao, valor_compra, acordo, parcelas, id_contrato) {
  return {
    loja,
    numero_documento,
    dt_emissao,
    valor_compra,
    acordo,
    parcelas,
    id_contrato,
  };
}

function Row(props) {
  const { row,  setRows, getDadosCliente, setEditOpen, setEventAcordo, history, contrato } = props;
  const [open, setOpen] = React.useState(true);
  const [parcelasSelected, setParcelasSelected] = useState(row.parcelas.filter(el => el.paga).map(el => el.id_parcela));
  const [sweet, setSweet] = useState({
    show: false,
    type: '',
    title: '',
    action: '',
  });
  const classes = useRowStyles();

  const getValorTotalSelecionado = (id_contrato = null) => {
    let result = 0;
    row.parcelas.map(parcelasRow => {
      if (document.getElementById('check-' + parcelasRow.id_parcela) != null) {
        if (document.getElementById('check-' + parcelasRow.id_parcela).checked && !parcelasRow.paga) {
          if (id_contrato !== null) {
            let soma_valor = document
              .getElementById('soma_valor' + parcelasRow.id_parcela)
              .value.replace('R$ ', '')
              .replace(',', '.');
            if (soma_valor == '') {
              soma_valor = 0;
            }
            parcelasSelected.map(id_parcela => {
              if (parcelasRow.id_parcela == id_parcela) {
                result = result + getValorFinal(parcelasRow.dt_vencimento, parcelasRow.valor_vencimento, soma_valor, parcelasRow.data_pagamento);
              }
            });
          } else {
            result = result + getValorFinal(parcelasRow.dt_vencimento, parcelasRow.valor_vencimento, parcelasRow.soma_valor, parcelasRow.data_pagamento);
          }
        }
      }
    });

    result = Math.round(result * 100) / 100;
    if (id_contrato !== null) {
      document.getElementById('valor_final' + id_contrato).innerHTML = `Valor Total Selecionado: R$ ${result.toString().replace('.', ',')}`;
      document.getElementById('baixa-' + id_contrato).style.display = result > 0 ? 'block' : 'none';
    }
    return result;
  };

  const setAcresDesc = (id_parcela, value) => {
    document.getElementById('soma_valor' + id_parcela).value = value;
    let valor_final = value.replace('R$ ', '').replace(',', '');
    if (valor_final == '') {
      valor_final = 0;
    }
    const data = {
      soma_valor: valor_final,
    };
    updateParcela(id_parcela, data)
      .then(response => {
        const responseData = response.data.data;
        if (response.data.status) {
          document.getElementById('cell-valorfinal-table-' + id_parcela).innerHTML = `R$ ${getValorFinal(
            responseData.dt_vencimento,
            responseData.valor_vencimento,
            responseData.soma_valor,
            responseData.data_pagamento,
          )
            .toString()
            .replace('.', ',')}`;
          let valor_total_selecionado = 0;
          parcelasSelected.map(id_parcela => {
            let valor_final_row = parseFloat(
              document
                .getElementById('cell-valorfinal-table-' + id_parcela)
                .innerHTML.replace('R$ ', '')
                .replace(',', '.'),
            );
            valor_total_selecionado = valor_total_selecionado + valor_final_row;
          });
          if (document.getElementById('check-' + id_parcela).checked) {
            document.getElementById('valor_final' + responseData.id_contrato).innerHTML = `Valor Total Selecionado: R$ ${valor_total_selecionado.toString().replace('.', ',')}`;
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleCloseSweet = () => {
    setSweet({
      show: false,
      type: '',
      title: '',
      action: '',
    });
  }

  const handleParcelasSelected = (checked, id_parcela, id_contrato) => {
    let newArray = parcelasSelected;
    if (checked) {
      newArray.push(id_parcela);
      setParcelasSelected(newArray);
    } else {
      newArray.map((item, index) => {
        if (item === id_parcela) {
          newArray.splice(index, 1);
        }
      });
      setParcelasSelected(newArray);
    }
    getValorTotalSelecionado(id_contrato);
  };

  const sendBaixaParcela = id_contrato => {
    const data = {
      parcelas: contrato.parcelas.filter(parc => parcelasSelected.some(id => id === parc.id_parcela)).filter(parc => !parc.paga).map(parc => parc.id_parcela),
    };
    baixaParcelas(id_contrato, data)
      .then(response => {
        if (response.data.status) {
          toast.success('Baixa das parcelas realizada com sucesso !');
          data.parcelas.map(id_parcela => {
            document.getElementById('check-' + id_parcela).disabled = true;
          });
          setParcelasSelected([]);
          setRows([]);
          getDadosCliente();
          handleCloseSweet();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const sendRemoveBaixa = id_parcela => {
    removerBaixaParcela(id_parcela)
      .then(response => {
        if (response.data.status) {
          toast.success('Baixa retirada com sucesso !');
          setRows([]);
          getDadosCliente();
          handleCloseSweet();
        }
      })
      .catch(error => {
        console.log(error);
      });

  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.loja}
        </TableCell>
        <TableCell align="right">{row.numero_documento}</TableCell>
        <TableCell align="right">{row.dt_emissao}</TableCell>
        <TableCell align="right">R$ {row.valor_compra.toString().replace('.', ',')}</TableCell>
        <TableCell align="right">
          {row.acordo.id === null ? (
            <Button variant="outlined" color="warning" onClick={() => history.push('../../../app/acordos')}>
              Fazer acordo
            </Button>
          ) : (
            <Button
              onClick={() => {
                setEventAcordo(row.acordo);
                setEditOpen(true);
              }}
              variant="outlined"
              color="primary"
            >
              Ver <OpenInNewIcon style={{ marginLeft: '10px' }} />{' '}
            </Button>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Parcelas
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Parcela</b>
                    </TableCell>
                    <TableCell>
                      <b>Data do vencimento</b>
                    </TableCell>
                    <TableCell>
                      <b>Valor do Vencimento</b>
                    </TableCell>
                    <TableCell>
                      <b>Dias vencidos</b>
                    </TableCell>
                    <TableCell align="right">
                      <b>Juros</b>
                    </TableCell>
                    <TableCell align="right">
                      <b>+Acresc/-Desc</b>
                    </TableCell>
                    <TableCell align="right">
                      <b>Valor a cobrar</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.parcelas.map((parcelasRow, index) => (
                    <TableRow key={parcelasRow.dt_vencimento}>
                      <TableCell component="th" scope="row">
                        {
                          parcelasRow.paga ? (
                            <Checkbox
                              id={`check-${parcelasRow.id_parcela}`}
                              onChange={(e) => {
                                setSweet({
                                  show: true,
                                  type: 'warning',
                                  title: `Você está prestes a remover a baixa da parcela ${parcelasRow.ordem_documento}/${parcelasRow.qtd_documento}, deseja continuar ?`,
                                  action: (id) => {
                                    sendRemoveBaixa(parcelasRow.id_parcela)
                                  },
                                });
                              }}
                              checked
                              color="primary"
                            />
                          ) : (
                            <Checkbox
                              id={`check-${parcelasRow.id_parcela}`}
                              onChange={(e) => {
                                handleParcelasSelected(e.target.checked, parcelasRow.id_parcela, row.id_contrato);
                              }}
                              color="primary"
                            />
                          )
                        }
                        
                        {parcelasRow.ordem_documento}/{parcelasRow.qtd_documento}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {formatDate(parcelasRow.dt_vencimento)}
                      </TableCell>
                      <TableCell>R$ {parcelasRow.valor_vencimento.replace('.', ',')}</TableCell>
                      <TableCell>
                        {-getDiasVencidos(parcelasRow.dt_vencimento, parcelasRow.data_pagamento) < 0 ? (
                          <span style={{ color: 'red' }}>{getDiasVencidos(parcelasRow.dt_vencimento, parcelasRow.data_pagamento)} dias</span>
                        ) : (
                          <span style={{ color: 'green' }}>{-getDiasVencidos(parcelasRow.dt_vencimento, parcelasRow.data_pagamento)} dias</span>
                        )}
                      </TableCell>

                      <TableCell align="right">R$ {calculoJuros(parcelasRow.dt_vencimento, parcelasRow.valor_vencimento, parcelasRow.data_pagamento).toString().replace('.', ',')}</TableCell>

                      <TableCell align="right">
                        <TextField
                          style={{ width: '60%', minWidth: '100px' }}
                          variant="outlined"
                          defaultValue={row.parcelas[index].soma_valor}
                          onBlur={e => {
                            setAcresDesc(parcelasRow.id_parcela, e.target.value);
                            document.getElementById(e.target.id).value = e.target.value === '' ? 0 : e.target.value;
                          }}
                          id={`soma_valor${parcelasRow.id_parcela}`}
                          InputProps={{
                            inputComponent: CurrencyInput,
                          }}
                          disabled={parcelasRow.paga}
                        />
                      </TableCell>
                      <TableCell id={`cell-valorfinal-table-${parcelasRow.id_parcela}`} align="right">
                        R$ {getValorFinal(parcelasRow.dt_vencimento, parcelasRow.valor_vencimento, parcelasRow.soma_valor, parcelasRow.data_pagamento).toString().replace('.', ',')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <div id={`baixa-${row.id_contrato}`} style={{ float: 'left', padding: '10px', display: 'none' }}>
                <Button
                  onClick={() =>
                    setSweet({
                      show: true,
                      type: 'warning',
                      title: 'Você está prestes a dar baixa nas parcelas selecionadas, deseja continuar ?',
                      action: sendBaixaParcela,
                    })
                  }
                  variant="outlined"
                  color="primary"
                >
                  Dar Baixa
                </Button>
              </div>
              <div style={{ float: 'right', padding: '10px' }}>
                <b id={`valor_final${row.id_contrato}`}>Valor Total Selecionado: R$ {getValorTotalSelecionado().toString().replace('.', ',')}</b>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {sweet.show ? <MySweetAlert type={sweet.type} title={sweet.title} setOpenSweet={setSweet} id={row.id_contrato} action={sweet.action} showCancel={true} /> : ''}
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    numero_documento: PropTypes.string.isRequired,
    valor_compra: PropTypes.number.isRequired,
    dt_emissao: PropTypes.string.isRequired,
    parcelas: PropTypes.arrayOf(
      PropTypes.shape({
        valor_compra: PropTypes.number.isRequired,
        valor_vencimento: PropTypes.number.isRequired,
        dt_vencimento: PropTypes.string.isRequired,
      }),
    ).isRequired,
    loja: PropTypes.string.isRequired,
  }).isRequired,
};

export default function Debitos(props) {
  const { children, match, setDataContratosRecibo } = props;
  const [editOpen, setEditOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [eventAcordo, setEventAcordo] = useState({ id: '' });
  const [contrato, setContrato] = useState([]);
  const [parcelas, setParcelas] = useState([]);

  useEffect(() => {
    getDadosCliente();
  }, []);

  const getDadosCliente = () => {
    children(true);
    getDataClient(match.params.id)
      .then(response => {
        const clienteData = response.data.data;
        const contratoData = clienteData.contratos;
        const parcelasData = contratoData.parcelas;
        if (response.data.status) {
          setDataContratosRecibo(contratoData);
          setContrato(contratoData);
          setParcelas(parcelasData);
        }
        listDebitos(contratoData);
        children(false);
      })
      .catch(error => {
        children(false);
        console.log(error);
        //props.history.push('../clients');
      });
  };

  const listDebitos = contrato => {
    let newRow = [];
    contrato.map(item => {
      newRow.push(createData(item.loja, item.numero_documento, formatDate(item.dt_emissao), item.valor_compra, item.acordo, item.parcelas, item.id_contrato));
    });
    setRows(newRow);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Loja</TableCell>
              <TableCell align="right">
                <b>Número do Documento</b>
              </TableCell>
              <TableCell align="right">
                <b>Data da Emissão</b>
              </TableCell>
              <TableCell align="right">
                <b>Valor Original</b>
              </TableCell>
              <TableCell align="right">
                <b>Acordo</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <Row history={props.history} contrato={contrato.find(el => el.id_contrato === row.id_contrato)} getDadosCliente={getDadosCliente} setRows={setRows} setEditOpen={setEditOpen} setEventAcordo={setEventAcordo} key={row.loja} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {editOpen ? <AlteraAcordo setLoading={children} listAcordos={() => console.log(1)} editOpen={editOpen} setEditOpen={setEditOpen} event={eventAcordo} /> : ''}
    </>
  );
}
