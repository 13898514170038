import DateFnsUtils from '@date-io/date-fns';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from '@material-ui/core'
import { KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import CurrencyInput from '../../../components/Inputs/CurrencyInput';
import MaskInputs from '../../../components/Inputs/MaskInputs';
import { cleanObj, formatAmount, getOnlyNumber, parseAmountFloat } from '../../../shared/Utils';
import useStyles from '../../../shared/Styles';
import EmprestimoService from '../../../services/EmprestimoService';
import { toast } from 'react-toastify';

export default function ModalEmprestimo({ loader, handleClose, idEmprestimo, listEmprestimos }) {

    const classes = useStyles();

    const [newEmprestimo, setNewEmprestimo] = useState(true);
    const messageRequired = "Este campo é obrigatório";
    const [dataEmprestimo, setDataEmprestimo] = useState({
        data_emprestimo: moment().add('days', 1).format('YYYY-MM-DD'),
        nome: "",
        qtd_parcelas: 24,
        valor: "",
        cpf_cnpj: "",
        telefone: "",
        endereco: ""
    });
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(idEmprestimo) {
            setNewEmprestimo(false);
            loadDataEmprestimo();
        }
    }, []);

    const loadDataEmprestimo = () => {
        loader(true);
        EmprestimoService.search(idEmprestimo).then(
            response => {
                const finalData = response.data.data;
                setDataEmprestimo(prepareItem(finalData));
                loader(false);
            }
        ).catch(error => {
            loader(false);
        })
    }

    const handleChange = (e) => {
        if(e.target) {
            const {name, value} = e.target;
            setDataEmprestimo({...dataEmprestimo, [name] : value, });
        }else{
            setDataEmprestimo({...dataEmprestimo, data_emprestimo: e})
        }
    }

    const prepareItem = (item) => {
        delete item["dados_parcelas"];
        delete item["created_at"];
        delete item["updated_at"];
        Object.keys(item).forEach(key => {
            if(item[key] == null) {
                delete item[key];
                item[key] = "";
            }
        });
        return item;
    }

    const handleSubmit = () => {
        if(validFields()) {
            setLoading(true);
            let data = {
                ...dataEmprestimo,
                data_emprestimo: dataEmprestimo.data_emprestimo,
                valor: parseAmountFloat(dataEmprestimo.valor)
            }
            data = cleanObj(data);
            newEmprestimo ? saveEmprestimo(data) : updateEmprestimo(data);
        }
    }

    const saveEmprestimo = (data) => {
        EmprestimoService.save(data).then(
            response => {
                if(response.data.status){
                    listEmprestimos();
                    handleClose();
                    toast.success("Emprestimo salvo com sucesso !");
                }
                setLoading(false);
            }
        ).catch(error => {
            setLoading(false);
        })
    }

    const updateEmprestimo = (data) => {
        EmprestimoService.update(idEmprestimo, data).then(
            response => {
                if(response.data.status){
                    listEmprestimos();
                    handleClose();
                    toast.success("Emprestimo atualizado com sucesso !");
                }
                setLoading(false);
            }
        ).catch(error => {
            setLoading(false);
        })
    }

    const validFields = () => {
        
        const fieldsRequired = ["nome", "data_emprestimo", "qtd_parcelas", "valor"];
        
        const fieldErrors = [];

        fieldsRequired.map(field => {
            if(!dataEmprestimo[field]) {
                fieldErrors.push(field);
            }
        });

        if(fieldErrors.length > 0) {
            setErrors(fieldErrors);
            return false;
        }
        setErrors([]);
        return true;

    }

    const verifyRequired = (field) => {
        return errors.find(element => element === field) ? true : false;
    }

    return (
        <Dialog maxWidth={"md"} open aria-labelledby="form-dialog-title">

            <DialogTitle id="form-dialog-title">{ newEmprestimo ? "Cadastrar novo emprestimo" : "Editar emprestimo"}</DialogTitle>

            <DialogContent>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>

                <Grid container spacing={2}>

                    <Grid item xs={12} md={4}>
                        <KeyboardDatePicker
                            fullWidth
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            name="data_emprestimo"
                            id="date-picker-inline"
                            label="Data do emprestimo"
                            required
                            value={moment(dataEmprestimo.data_emprestimo).add('days', 1).format('YYYY-MM-DD')}
                            onChange={handleChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField 
                            fullWidth 
                            name="nome"
                            label="Nome"
                            value={dataEmprestimo.nome}
                            error={verifyRequired("nome")}
                            helperText={verifyRequired("nome") && messageRequired}
                            onChange={handleChange}
                            required
                            variant="outlined" />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <TextField 
                            fullWidth 
                            id="nome"
                            name="qtd_parcelas"
                            value={dataEmprestimo.qtd_parcelas}
                            error={verifyRequired("qtd_parcelas")}
                            helperText={verifyRequired("qtd_parcelas") && messageRequired}
                            type="number"
                            label="Parcelas" 
                            InputProps={{
                                inputProps: { 
                                    max: 48, min: 1
                                },
                                readOnly: !newEmprestimo
                            }}
                            defaultValue={24}
                            onChange={handleChange}
                            required
                            variant="outlined" />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField 
                            fullWidth 
                            label="Valor" 
                            name="valor"
                            error={verifyRequired("valor")}
                            helperText={verifyRequired("valor") && messageRequired}
                            value={dataEmprestimo.valor}
                            InputProps={{
                                inputComponent: CurrencyInput,
                            }}
                            required
                            onChange={handleChange}
                            variant="outlined" />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField 
                            fullWidth 
                            name="cpf_cnpj"
                            label="CPF/CNPJ"
                            value={dataEmprestimo.cpf_cnpj}
                            maxLength={18}
                            InputProps={{
                                inputComponent: MaskInputs,
                                type: getOnlyNumber(dataEmprestimo.cpf_cnpj).length > 11 ? 'cnpj' : 'cpf'
                            }}
                            error={verifyRequired("cpf_cnpj")}
                            helperText={verifyRequired("nome") && "CPF/CNPJ inválidos"}
                            onChange={handleChange}
                            variant="outlined" />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField 
                            fullWidth 
                            name="telefone"
                            label="Telefone"
                            value={dataEmprestimo.telefone}
                            InputProps={{
                                inputComponent: MaskInputs,
                                type: 'telefone'
                            }}
                            onChange={handleChange}
                            variant="outlined" />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField 
                            fullWidth 
                            name="endereco"
                            value={dataEmprestimo.endereco}
                            label="Endereco"
                            onChange={handleChange}
                            variant="outlined" />
                    </Grid>

                </Grid>
                
                </MuiPickersUtilsProvider>
                
            </DialogContent>

            <DialogActions>
                <Button variant="contained" onClick={handleClose} color="secondary">
                    Cancelar
                </Button>
                <div className={classes.wrapper}>
                    <Button disabled={loading} variant="contained" onClick={handleSubmit} color="primary">
                        Salvar
                    </Button>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              
            </DialogActions>
      </Dialog>
    )
}
