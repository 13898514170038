import api from './api';

export function getNotifications(){
    return api.get(getURI());
}

export function clearNotifications(){
    return api.post('clear-notifications');
}

function getURI(){
    return 'notifications';
}