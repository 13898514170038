import { Button } from '@material-ui/core';
import React from 'react';

import SweetAlert from 'react-bootstrap-sweetalert';

export default function MySweetAlert(props) {

    const { type, title, setOpenSweet, action, id, showCancel } = props;

    return (
        <SweetAlert 
                type={type}
                title={title}
                onConfirm={() => action(id)}
                onCancel={() => setOpenSweet(false)}
                cancelBtnText={"Cancelar"}
                confirmBtnText={<Button variant="outlined" color="primary" >Confirmar</Button>}
                cancelBtnText={<Button variant="outlined" color="secondary">Cancelar</Button>}
                confirmBtnStyle={{textDecoration: "none"}}
                cancelBtnStyle={{textDecoration: "none"}}
                showCancel={showCancel}
                dependencies={["Reinaldo","Ribeiro"]}
        />
    )
}
