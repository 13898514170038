import api from './api';
import jwt_decode from "jwt-decode";

function loginUser(dispatch, user, passwordValue, history, setIsLoading, setStatus401, setUser) {

    setIsLoading(true);
    setStatus401(false);
  
    api.post('login', {
      "user" : user,
      "password" : passwordValue
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(response => {
  
      localStorage.setItem("JWT_TOKEN", response.data.token);
      const dataUser = jwt_decode(response.data.token).sub;
      setUser(dataUser)
      setIsLoading(false);
      history.push("app/acordos");
      dispatch({ type: "LOGIN_SUCCESS" });
  
    }).catch(error => {
        if(error.response !== undefined){
          setStatus401(true);
        }
        setIsLoading(false);
    });
  }
  
function signOut(dispatch, history) {
    localStorage.removeItem("JWT_TOKEN");
    dispatch({ type: "SIGN_OUT_SUCCESS" });
    history.push("/login");
}

export { loginUser, signOut };