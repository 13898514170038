import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Clients from "../../pages/clients/Clients";
import ShowClients from "../../pages/clients/components/ShowClient";
import Dashboard from "../../pages/dashboard/Dashboard";
import Emprestimos from "../../pages/emprestimos/Emprestimos";

// context
import { useLayoutState } from "../../context/LayoutContext";
import Calendar from "../../pages/calendar/Calendar";
import Loader from "../Loader/Loader";
import Users from "../../pages/users/Users";
import Relatorios from "../../pages/relatorios/Relatorios";
import { useUser } from "../../context/UserContext";

const NoMatchPage = () => {
  return (
    <Redirect
      to={{
        pathname: '/app/acordos'
      }}
    />
  );
}


function Layout(props) {

  const {children} = props;

  var classes = useStyles();

  // global
  var layoutState = useLayoutState();
  const {dataUser} = useUser();
  const [ isLoading, setLoading ] = useState(false);

  return (
    <div className={classes.root}>
        <>
          {
            isLoading ? (
              <Loader width="120px" height="120px" />   
            ) : ""
          }

          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
              <Switch>

                  <Route
                    setLoading={setLoading}
                    path="/app/acordos"
                    render={props => (
                      React.createElement(Calendar, props, setLoading)
                    )}
                  />

                  <Route 
                      setLoading={setLoading}
                      path="/app/clients"
                      render={props => (
                        React.createElement(Clients, props, setLoading)
                      )}
                  />
                
                  <Route
                    setLoading={setLoading}
                    path="/app/reports" 
                    render={props => (
                      React.createElement(Relatorios, props, setLoading)
                    )}
                  />

                  <Route 
                    setLoading={setLoading}
                    path="/app/client/:id" 
                    render={props => (
                      React.createElement(ShowClients, props, setLoading)
                    )}
                  />

                  {
                    dataUser.is_admin ? (
                      <>
                      <Route 
                        setLoading={setLoading} 
                        path="/app/dashboard" 
                        render={props => (
                          React.createElement(Dashboard, props, setLoading)
                        )} />
                      
                      <Route 
                        setLoading={setLoading} 
                        path="/app/emprestimos" 
                        render={props => (
                          React.createElement(Emprestimos, props, setLoading)
                        )} />
                      
                      <Route
                        setLoading={setLoading}
                        path="/app/users" 
                        render={props => (
                          React.createElement(Users, props, setLoading)
                        )} />
                      </>
                    ) : <Route  path="/" component={NoMatchPage} />
                  }
          
              </Switch>
          </div>
        </>
    </div>
  );
}

export default withRouter(Layout);
