import React, { useState } from "react";
import jwt_decode from "jwt-decode";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();
var UserContext = React.createContext();


function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {

  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("JWT_TOKEN"),
  });

  const [dataUser, setDataUser] = useState(!!localStorage.getItem("JWT_TOKEN") ? jwt_decode(localStorage.getItem("JWT_TOKEN")).sub  : {});
  
  async function applyUser(data) {
    setDataUser(data);
  }

  return (
    <UserContext.Provider value={{dataUser, applyUser}}>
      <UserStateContext.Provider value={state}>
          <UserDispatchContext.Provider value={dispatch}>
            {children}
          </UserDispatchContext.Provider>
        </UserStateContext.Provider>
    </UserContext.Provider>
   
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

function useUser() {
  var context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, useUser };
