import { toast } from 'react-toastify';
import api from './api';


export function SendLinkReset(email){
    return api.post('password/email', {"email" : email});
}

export function getResetPassword(token) {
    return api.get(`password/reset/${token}`);
}

export function recoveryPassword(data) {
    return api.post(`password/reset`, data);
}