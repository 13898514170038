// UncontrolledLottie.jsx
import React, { Component } from 'react'
import Lottie from 'react-lottie'
import animationData from '../../assets/json/loader.json';
import "./style.css";

class Loader extends Component {

    constructor(props){
        super(props);
        this.state = {
            height: this.props.height,
            width: this.props.width
        }
    }

    render() {

        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return (
            <div className="loading">
                <Lottie
                    options={defaultOptions}
                    height={this.state.height}
                    width={this.state.width}
                    speed={2}
                />
            </div>
        )
    }
}

export default Loader