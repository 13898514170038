
import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import { makeStyles } from "@material-ui/styles";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const useStyles = makeStyles((theme) => ({
    dropZone: {
        width: "100%",
        minWidth: "300px",
        borderWidth: "medium",
        borderRadius: "6px",
        borderStyle: "dashed",
        borderColor: "black",
        padding: "20px",
    },
    content: {
        display: "flex", 
        justifyContent: "center",
        alignItems: "center",
    }
}));

export default function DropzoneWithoutDrag(props) {

    const { setFile } = props;

    const onDrop = useCallback(acceptedFiles => {
       setFile(acceptedFiles[0]);
    }, []);
 
    const {getRootProps, getInputProps, acceptedFiles} = useDropzone({onDrop});
    const files = acceptedFiles.map(file => <li key={file.path}>{file.path}</li>);

    const classes = useStyles();
    
   

  return (
    <section className="container">
      <div {...getRootProps({className: classes.dropZone})}>
        <input {...getInputProps()} />
        <p  className={classes.content}>Clique ou arraste a ficha aqui !</p>
        <em className={classes.content}> <CloudUploadIcon /></em>
      </div>
      <aside>
          {
              files.length !== 0 ? (
                  <>
                    <h4 style={{color: "green"}}>Arquivo carregado com sucesso !</h4>
                    <ul>{files}</ul>
                  </>
              ): ""
          }
      
      </aside>
    </section>
  );
}