import React, { useState, useEffect } from 'react';
import { Fab, FormControlLabel, Grid, Switch, Tooltip } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';

// components
import PageTitle from '../../components/PageTitle/PageTitle';
import { getClientes } from '../../services/ClienteService';
import { toast } from 'react-toastify';
import { formatDate, formatCPF, formatTelefone } from '../../shared/Utils';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import useStyles from '../../shared/Styles';
import ImportarFicha from './components/ImportarFicha';
import MySweetAlert from '../../components/SweetAlert';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CustomerIcon from '../../assets/icons/customer.svg';
import { Button } from '../../components/Wrappers/Wrappers';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ListaRecibo from './components/ListaRecibos';

export default function Clients(props) {
  const { children } = props;
  const classes = useStyles();
  const [dataTable, setDataTable] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [open, setOpen] = useState(false);
  const [lengthData, setLengthData] = useState(0);
  const [openSweet, setOpenSweet] = useState(false);
  const [messageSweet, setMesssageSweet] = useState('');
  const [check, setCheck] = useState(false);
  const [openListaRecibo, setOpenListaRecibo] = useState(false);
  const colorsStatusCobranca = {
    nivel0: '#6e6e6e',
    nivel1: '#6fbf73',
    nivel2: '#ffc107',
    nivel3: '#aa2e25',
    nivel4: '#083c78',
  };

  useEffect(() => {
    listClientes(check);
  }, []);

  const listClientes = show_deleted => {
    //
    children(true);
    getClientes(show_deleted)
      .then(response => {
        const responseData = response.data.data;
        const status = response.data.status;
        const length = response.data.data.length;

        if (status) {
          const newArray = [];

          responseData.map(item => {
            newArray.push([
              item.codigo_cliente,
              item.nome_cliente,
              formatCPF(item.cpf),
              formatTelefone(item.telefone),
              formatDate(item.created_at),
              <FiberManualRecordIcon
                style={{
                  color:
                    item.status_cobranca === 0
                      ? colorsStatusCobranca.nivel0
                      : item.status_cobranca === 1
                      ? colorsStatusCobranca.nivel1
                      : item.status_cobranca === 2
                      ? colorsStatusCobranca.nivel2
                      : item.status_cobranca === 3
                      ? colorsStatusCobranca.nivel3
                      : colorsStatusCobranca.nivel4,
                }}
              />,
              item.id_cliente,
            ]);
          });

          if (page != 1) {
            newArray.push(...dataTable);
            setDataTable(newArray);
          } else {
            setDataTable(newArray);
          }

          setLengthData(length);
          setPerPage(perPage);
          setPage(page);
          setTotal(length);
          children(false);
        }
      })
      .catch(error => {
        toast.error('Ocorreu um erro, não foi possível carregar os clientes, entre em contato com o Reinaldo !');
        console.log(error);
      });
  };

  const handleCloseListRecibo = () => {
    setOpenListaRecibo(false);
  };

  const changePerPage = e => {
    setPerPage(e);
    listClientes(check, e);
  };

  const changeList = () => {
    setCheck(!check);
    listClientes(!check);
  };

  const viewClient = codigo_cliente => {
    dataTable.map(item => {
      if (codigo_cliente == item[0]) {
        props.history.push(`client/${item[6]}`);
      }
    });
  };

  return (
    <>
      <PageTitle title="Lista de Clientes" icon={CustomerIcon} />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FormControlLabel control={<Switch checked={check} onChange={() => changeList()} name="check" color="primary" />} label="Mostrar somente fichas que já sairam" />

          <Button onClick={() => setOpenListaRecibo(true)} size="large" variant="contained" style={{ float: 'right', borderRadius: 50 }} startIcon={<ReceiptIcon />} color="primary">
            Recibos
          </Button>
        </Grid>
        <Grid style={{ marginBottom: '50px' }} item xs={12}>
          <MUIDataTable
            title="Clientes Atuais"
            data={dataTable}
            columns={['Código do Cliente', 'Nome do Cliente', 'CPF', 'Telefone', 'Data de importação', 'Status']}
            options={{
              filter: true,
              page: 0,
              pagination: true,
              rowsPerPageOptions: [5, 10, 20, 50, total],
              onChangeRowsPerPage: e => changePerPage(e),
              count: lengthData,
              textLabels: {
                body: {
                  noMatch: 'Desculpe, não encontrei nenhum resultado !',
                  toolTip: 'Ordenar',
                  columnHeaderTooltip: column => `Ordenar por ${column.label}`,
                },
                pagination: {
                  next: 'Próxima Página',
                  previous: 'Página Anterior',
                  rowsPerPage: 'Linhas por página:',
                  displayRows: 'de',
                },
                toolbar: {
                  search: 'Pesquisar',
                  downloadCsv: 'Baixar CSV',
                  print: 'Imprimir',
                  viewColumns: 'Visualizar Colunas',
                  filterTable: 'Filtrar Tabela',
                },
                filter: {
                  all: 'Todos',
                  title: 'Filtros',
                  reset: 'Resetar',
                },
                viewColumns: {
                  title: 'Visualizar Colunas',
                  titleAria: 'Visualizar/Esconder colunas da tabela',
                },
                selectedRows: {
                  text: 'linha(s) selecionadas',
                  delete: 'Deletar',
                  deleteAria: 'Delete Selected Rows',
                },
              },
              filterType: 'multiselect',
              selectableRowsOnClick: false,
              selectableRowsHideCheckboxes: false,
              selectableRows: false,
              onRowClick: rowData => viewClient(rowData[0]),
            }}
          />
        </Grid>
      </Grid>

      <span className={classes.floatButton}>
        <Tooltip title="Importar Ficha" aria-label="Importar Ficha">
          <Fab onClick={() => setOpen(true)} color="primary" aria-label="add">
            <CloudUploadIcon />
          </Fab>
        </Tooltip>
      </span>

      {openListaRecibo && <ListaRecibo loading={children} handleClose={handleCloseListRecibo} />}

      {openSweet && <MySweetAlert type="success" title={messageSweet} setOpenSweet={setOpenSweet} id={false} action={setOpenSweet} showCancel={false} />}

      {open && <ImportarFicha setCheck={setCheck} setMesssageSweet={setMesssageSweet} listClientes={listClientes} open={open} setOpen={setOpen} setOpenSweet={setOpenSweet} />}
    </>
  );
}
