import api from './api';

class EmprestimoService {
    
    list(){
        return api.get(this.getURI());
    }
    
    search(id) {
        return api.get(`${this.getURI()}/${id}`);
    }
    
    save(data){
        return api.post(this.getURI(), data);
    }
    
    update(id, data) {
        return api.patch(`${this.getURI()}/${id}`, data);
    }

    delete(id) {
        return api.delete(`${this.getURI()}/${id}`);
    }
    
    getURI(){
        return 'emprestimo';
    }
    
}

export default new EmprestimoService();

