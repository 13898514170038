export  function formatDate(date){
    if(date == undefined){
        return null;
    }
    const explode = date.split("-");
    return explode[2].substring(0,2) +'/'+ explode[1] + '/' + explode[0]; 
}

export  function formatCPF(cpf){
    if(cpf == undefined){
        return null;
    }
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, 
    function( regex, argumento1, argumento2, argumento3, argumento4 ) {
            return argumento1 + '.' + argumento2 + '.' + argumento3 + '-' + argumento4;
    });
}

export function formatTelefone(telefone){
    if(telefone == null){
        return null;
    }
    if(telefone.length <= 11){
        const parte1 = `(${telefone.substring(0,2)}) 9 `;
        const parte2 = `${telefone.substring(2,6)}-` 
        const parte3 = telefone.substring(6,10);
        return parte1 + parte2 + parte3;
    }

    return telefone;
    
}

export function transformDate(date) {
    const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
    const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
    return `${year}-${month}-${day}`;
}

export function formatAmount(amount) {
    return amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

export function getOnlyNumber(value) {
    return value.replace(/\D/g, "");
}

export function parseAmountFloat(value)
{
    return parseFloat(value.replace("R$ ","").replace(",", ""));
}

export function cleanObj(data){
    Object.keys(data).forEach((key) => (data[key] == "") && delete data[key]);
    return data;
}

export function getDiasVencidos (data_vencimento, data_pagamento = null) {
    const dataAtual = data_pagamento !== null ? new Date(data_pagamento + ' 00:00:00') : new Date();
    dataAtual.setHours(0,0,0,0);
    const dt_vencimento = new Date(data_vencimento + ' 00:00:00');
    const diff  = Math.abs(dataAtual - dt_vencimento);
    const dias = Math.ceil(diff/ (1000 * 60 * 60 * 24));
    return dt_vencimento > dataAtual ? -dias :  dias;
}

export function calculoJuros(data_vencimento, valor_parcela, data_pagamento){
    const dias = getDiasVencidos(data_vencimento, data_pagamento);  

    let porcentagem = 0.004;

    if(dias > 0 && dias <= 100){
        porcentagem = 0.004;
    }else if(dias > 100 && dias <= 180){
        porcentagem = 0.003;
    }else if(dias > 180 && dias <= 250){
        porcentagem = 0.002;
    }else if(dias > 250 && dias <= 731) {
        porcentagem = 0.0015;
    }else if(dias > 731){
        porcentagem = 0.001;
    }else if(dias < 0){
        porcentagem = 0;
    }

    const juros = (valor_parcela * porcentagem)*dias;
    return Math.round(juros * 100) / 100;
}

export function getValorFinal(data_vencimento, valor_parcela, soma_valor, data_pagamento = null){
    const juros = calculoJuros(data_vencimento, valor_parcela, data_pagamento);
    return Math.round( ( parseFloat(juros) + parseFloat(valor_parcela) + parseFloat(soma_valor) ) * 100 ) / 100 ;
}

export function betweenData(data_verify, data_inicial=null, data_final=null)
{
    data_inicial = !data_inicial ? new Date('2020-01-01') : new Date(data_inicial);
    data_final = !data_final ? new Date() : new Date(data_final);
    data_verify = new Date(data_verify);

    if(data_verify.getTime() >= data_inicial.getTime() && data_verify.getTime() <= data_final.getTime()){
        return true;
    }
    return false;

}

export function print(id) {
  var printContents = document.getElementById(id).innerHTML;
  document.body.innerHTML = printContents;
  window.print();
  window.location.reload();
}

