import { toast } from 'react-toastify';
import api from './api';

export function getAcordos(){
    return api.get(`${getURI()}?allResults=true`);
}

export function setAcordos(data){
    return api.post(getURI(), data);
}

export function getAcordo(id){
    return api.get(`${getURI()}/${id}`);
}

export function getAcordoByContrato(id_contrato){
    return api.get(`${getURI()}-contrato/${id_contrato}`);
}

export function updateAcordo(id, data){
    return api.patch(`${getURI()}/${id}`, data);
}

export function deleteAcordo(id){
    return api.delete(`${getURI()}/${id}`);
}

export function setStatusPag(id){
    return api.post(`${getURI()}-status/${id}`);
}

function getURI(){
    return 'acordo';
}