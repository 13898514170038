import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CheckImage from '../../../assets/icons/check.png';
import { Badge, Grid, TextField, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import MySweetAlert from '../../../components/SweetAlert';
import EmprestimoService from '../../../services/EmprestimoService';
import { toast } from 'react-toastify';
import moment from 'moment';
import { formatAmount, formatCPF } from '../../../shared/Utils';
import MaskInputs from '../../../components/Inputs/MaskInputs';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  badge: {
    top: 3,
    right: 3,
    float: 'right',
    fontSize: '30px !important',
    '& .MuiBadge-badge': {
      fontSize: 20,
      width: 30,
      height: 30,
    },
  },
  card: {
      width: "100%",
      height: 180,
      justifyContent: "center",
      alignSelf: "center",
      alignItems: "center",
      textAlign: "center"
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function VerEmprestimo({ handleClose, listEmprestimos, idEmprestimo, loader }) {

    const classes = useStyles();

    const [dataEmprestimo, setDataEmprestimo] = useState({});
    const [dataParcelas, setDataParcelas] = useState([]);
    const [sweet, setSweet] = useState({
        show: false,
        type: "",
        title:"",
        action:""
    });

    useEffect(() => {
        loadDataEmprestimo();
    }, []);

    const loadDataEmprestimo = () => {
        loader(true);
        EmprestimoService.search(idEmprestimo).then(
            response => {
                const data = response.data.data;
                setDataEmprestimo(data);
                setDataParcelas(JSON.parse(data.dados_parcelas));
                loader(false);
            }
        ).catch(error => {
            loader(false);
            return error;
        })
    }

    const deleteEmprestimo = () => {
        loader(true);
        EmprestimoService.delete(idEmprestimo).then(
            response => {
                if(response.data.status) {
                    handleClose();
                    listEmprestimos();
                    toast.success("Emprestimo deletado com sucesso !");
                }
                loader(false);
            }
        ).catch(error => {
            loader(false);
            return error;
        })
    }
  
    const handleCheckParcela = (id) => {
        const newDataParcelas = [];
        dataParcelas.map(item => {
            item.id === id ? newDataParcelas.push({
                id: item.id,
                paga: !item.paga,
                data_baixa: !item.paga ? moment().format('YYYY-MM-DD') : null,
            }) : newDataParcelas.push(item) 
        });
        const finalData = {  dados_parcelas: newDataParcelas };
        loader(true);
        EmprestimoService.update(idEmprestimo, finalData).then(
            response => {
                const data = response.data.data;
                if(response.data.status) {
                    setDataParcelas(data.dados_parcelas);
                    listEmprestimos();
                    setSweet({});
                }
                loader(false);
            }
        ).catch(error => {
            loader(false);
            return error;
        })
    }

    return (
        <div>
        <Dialog fullScreen open onClose={handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                {` ${ dataEmprestimo.nome } ( ${ moment(dataEmprestimo.data_emprestimo).format('DD/MM/YYYY')} )`}
                </Typography>
                <IconButton onClick={() => {
                    setSweet({
                        show: true,
                        type: "warning",
                        title: "Você está prestes a deletar o emprestimo, deseja continuar ?",
                        action: deleteEmprestimo
                    });
                }} aria-label="delete">
                    <DeleteIcon style={{color: "white"}} fontSize="medium" />
                </IconButton>
            </Toolbar>
            </AppBar>

            <Grid container style={{ padding: 20, maxWidth: "100%" }} container spacing={3}>
                <Grid item xs={4} sm={4}  md={2}>
                    <TextField 
                        fullWidth 
                        label="Parcelas" 
                        InputProps={{
                            readOnly: true,
                        }}
                        defaultValue=" " 
                        value={dataEmprestimo.qtd_parcelas}
                        variant="outlined" />
                </Grid>

                <Grid item xs={8} sm={8} md={3}>
                    <TextField 
                        fullWidth 
                        label="Valor" 
                        InputProps={{
                            readOnly: true
                        }}
                        defaultValue=" "
                        value={formatAmount(parseFloat(dataEmprestimo.valor))}
                        variant="outlined" />
                </Grid>

                { dataEmprestimo.cpf_cnpj && (
                      <Grid item xs={4} sm={4}  md={4}>
                      <TextField 
                          fullWidth 
                          label="CPF/CNPJ" 
                          InputProps={{
                              readOnly: true
                          }}
                          defaultValue=" "
                          value={formatCPF(dataEmprestimo.cpf_cnpj)}
                          variant="outlined" />
                  </Grid>
                )}

                { dataEmprestimo.telefone && (
                      <Grid item xs={4} sm={4}  md={3}>
                      <TextField 
                          fullWidth 
                          label="Telefone" 
                          InputProps={{
                              readOnly: true,
                              inputComponent: MaskInputs,
                              type: 'telefone'
                          }}
                          defaultValue=" "
                          value={dataEmprestimo.telefone}
                          variant="outlined" />
                  </Grid>
                )}

                { dataEmprestimo.endereco && (
                      <Grid item xs={12}>
                      <TextField 
                          fullWidth 
                          label="Endereco" 
                          InputProps={{
                              readOnly: true
                          }}
                          defaultValue=" "
                          value={dataEmprestimo.endereco}
                          variant="outlined" />
                  </Grid>
                )}


            </Grid>


            <Grid style={{ padding: 20, maxWidth: "100%" }} container spacing={3}>

            {dataParcelas.map(item => (
                <Grid key={item.id} xs={6} sm={4} md={2} data-testid="document" item>
                <div className={classes.root}>
                    <Badge badgeContent={item.id} color="primary" className={classes.badge} />
                    <Card >
                        <Tooltip 
                            title={item.paga ? `Marcada em ${moment(item.data_baixa).format('DD/MM/YYYY')}` : "Clique para marcar como pago"} 
                            aria-label="Parcela">
                            <CardActionArea 
                                onClick={() => {
                                    setSweet({
                                        show: true,
                                        type: "info",
                                        title: `Deseja ${item.paga ? "desmarcar" : "marcar"} o card ${item.id} como pago ?`,
                                        action: () => { handleCheckParcela(item.id) }
                                    });
                                }}
                                className={classes.card}>
                                { item.paga && (<img src={CheckImage} width={90}></img>)}
                            </CardActionArea>
                        </Tooltip>

                        <Typography variant="h6" align="center">
                            {item.paga ? `Marcada em ${moment(item.data_baixa).format('DD/MM/YYYY')}` : "Pendente"}
                        </Typography>
                        
                    </Card>
                </div>
                </Grid>
            ))}
            </Grid>
            
            {
                sweet.show && (
                    <MySweetAlert
                        type={sweet.type}
                        title={sweet.title}
                        setOpenSweet={setSweet}
                        id={""}
                        action={sweet.action}
                        showCancel={true}
                    />
                )
            }

        </Dialog>


        </div>
    );
}