import { FormControlLabel, Grid, Radio } from '@material-ui/core';
import React, {useEffect} from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { FaFilePdf } from 'react-icons/fa';

export default function RelParcelasBaixas(props) {

    const {setDataRel, report} = props;
    const [dataInicial, setDataInicial] = React.useState(new Date('2020-01-02'));
    const [dataFinal, setDataFinal] = React.useState(new Date());
    const [typeRel, setTypeRel] = React.useState("excel");

    useEffect(() => {
        loadDataRel();
    }, [])

    const loadDataRel = () => {
        setDataRel({
            data_inicial: dataInicial,
            data_final: dataFinal,
            type: typeRel
        })
    }

    const changeDataRel = (data_inicial, data_final, type) => {
        setDataRel({data_inicial, data_final, type});
    }

    return (
        <>
        
        <label>Selecione o Periodo</label>

            <Grid container spacing={3}>
                <Grid item xs={6}>
                   <MuiPickersUtilsProvider utils={DateFnsUtils}>
                   <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-initial"
                        label="Data Inicial"
                        format="dd/MM/yyyy"
                        value={dataInicial}
                        onChange={(e) => {
                            setDataInicial(e);
                            changeDataRel(e, dataFinal, typeRel);
                        }}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        />
                   </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                   <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-final"
                        label="Data Final"
                        format="dd/MM/yyyy"
                        value={dataFinal}
                        onChange={(e) => {
                            setDataFinal(e);
                            changeDataRel(dataInicial, e, typeRel);
                        }}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        />
                   </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12}> <label>Selecione como deseja gerar</label></Grid>
                <Grid item xs={6}>
                <FormControlLabel
                    value={"excel"}
                    checked={typeRel === "excel"}
                    onChange={(e) => {
                        setTypeRel(e.target.value);
                        changeDataRel(dataInicial, dataFinal, e.target.value); 
                    }}
                    control={<Radio color="primary" />}
                    label={<RiFileExcel2Fill style={{color: "green"}} size={40} />}
                    labelPlacement="end"
                    />
                 <FormControlLabel
                    value={"pdf"}
                    onChange={(e) =>  {
                        setTypeRel(e.target.value)
                        changeDataRel(dataInicial, dataFinal, e.target.value); 
                    }}
                    checked={typeRel === "pdf"}
                    control={<Radio color="primary" />}
                    label={ <FaFilePdf style={{color: "#b52521"}} size={40} />}
                    labelPlacement="end"
                    />
                </Grid>
            </Grid>
        </>
  );
}