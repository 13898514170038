import React from "react";

var LoaderStateContext = React.createContext(false);
var LoaderDispatchContext = React.createContext(()=> false);

function loaderReducer(state, action) {
    switch (action.type) {
      case true:
        return { ...state, isLoading: true };
      case false:
        return { ...state, isLoading: false };
      default: {
        throw new Error(`Unhandled action type: ${action.type}`);
      }
    }
}

function LoaderProvider({ children }) {

    const [state, dispatch] = React.useReducer(loaderReducer(state, dispatch), {
      isLoading: false
    });

    return (
      <LoaderStateContext.Provider value={state}>
        <LoaderDispatchContext.Provider value={dispatch}>
          {children}
        </LoaderDispatchContext.Provider> 
      </LoaderStateContext.Provider>
    );
}

function useLoaderState() {
    const context = React.useContext(LoaderStateContext);
    if (context === undefined) {
        throw new Error("useLoaderState must be used within a LoaderProvider");
    }
    return context;
}

function useLoaderDispatch() {
    const context = React.useContext(LoaderDispatchContext);
    if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
    }
    return context;
}

export { LoaderProvider, useLoaderState, useLoaderDispatch, showLoader, hideLoader };

// ###########################################################
function showLoader(dispatch){
    dispatch({type: false})
}
function hideLoader(dispatch){
    dispatch({type: true})
}

  