import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";

// styles
import useStyles from "./styles";

// logo
import logo from "./logo_white.png";
import logoBlack from "./logo.png";

// context
import { useUserDispatch, useUser} from "../../context/UserContext";
import ForgotPassword from "./ForgotPassword";
import { Close as CloseIcon } from "@material-ui/icons";
import 'react-toastify/dist/ReactToastify.min.css'; 
import { loginUser } from "../../services/AuthService";


function Login(props) {

  var classes = useStyles();

  // global
  var dispatch = useUserDispatch();
  var {applyUser, dataUser} = useUser();
  
  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState({
    error_user: false, 
    error_password: false
  });
  var [user, setUser] = useState("");
  var [activeTabId, setActiveTabId] = useState(0);
  var [passwordValue, setPasswordValue] = useState("");
  var [status401, setStatus401] = useState(false);

  const something = (event) => {
    if (event.keyCode === 13) {
      login()
    }
  }

  const login = () => {
    loginUser(dispatch, user, passwordValue, props.history, setIsLoading, setStatus401, applyUser);
  }

  const changeField = (field, value) => {

      if(field === "user"){
        setUser(value);
        value.length === 0 ? setError({ error_user : true, error_password: error.error_password}) : setError({ error_user : false, error_password: error.error_password});
      }

      if(field === "password"){
        setPasswordValue(value);
        value.length < 6 ? setError({error_user : error.error_user,error_password: true}) : setError({error_user : error.error_user,error_password: false});
      }
  }

  
  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>COBRANÇAS</Typography>
      </div>
      <div className={classes.formContainer}>
        <form autoComplete="off" onKeyDown={(e) => something(e)} className={classes.form}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Login" classes={{ root: classes.tab }} />
            <Tab label="Rec. Senha" classes={{ root: classes.tab }} />
          </Tabs>
          {activeTabId === 0 && (
            <React.Fragment>
              <Typography variant="h1" className={classes.greeting}>
                <img src={logoBlack} alt="logoBlack" className={classes.logotypeImage} />
              </Typography>

              <div style={{marginTop: "-25px"}}>
              <TextField
                    fullWidth
                    onChange={e => setUser(e.target.value)}
                    id="outlined-error-helper-text"
                    label="Usuário"
                    required
                    error={error.error_user}
                    defaultValue={user}
                    value={user}
                    onChange={event => changeField("user", event.target.value)}
                    helperText={error.error_user ? "Preencha o campo Usuário" : ""}
                    style={{marginBottom: 10}}
                />

              <TextField
                    fullWidth
                    type="password"
                    onChange={e => setUser(e.target.value)}
                    id="outlined-error-helper-text"
                    label="Senha"
                    required
                    error={error.error_user}
                    value={passwordValue}
                    onChange={e => changeField("password", e.target.value)}
                    helperText={error.error_user ? "O campo senha deve conter ao menos 6 digitos" : ""}
                />
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    onClick={login}
                    disabled={
                      user.length === 0 || passwordValue.length === 0
                    }
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Entrar
                  </Button>
                )}
              </div>
               
              </div>
            </React.Fragment>
          )}
        </form>
        
        {activeTabId === 1 && (
          <div className={classes.form}>
            <ForgotPassword />
          </div>
          )}
        <Typography color="primary" className={classes.copyright}>
          © 2020 DSCobranças. All rights reserved.
        </Typography>
      </div>
    
    </Grid>
  );
}


// #############################################################
function CloseButton({ closeToast, className }) {
  return <CloseIcon style={{position: "absolute"}}  className={className} onClick={closeToast} />;
}


export default withRouter(Login);
