import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PageTitle from '../../components/PageTitle/PageTitle';
import CadastrarUsuario from './components/CadastrarUsuario';
import { Divider } from '@material-ui/core';
import { getUsers, postUser } from '../../services/UserService';
import { toast } from 'react-toastify';
import { formatDate } from '../../shared/Utils';
import UserIcon from '../../assets/icons/users.svg';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function Users({children}) {

  const classes = useStyles();

  const [rows, setRows] = useState([]);

  useEffect(() => {
      listUsers();
  }, [])

  const listUsers = () => {
    children(true);
    getUsers().then(
        response => {
           const rowArray = [];
           const responseData = response.data.data;
           responseData.map(item => {
               rowArray.push({
                   name: item.name,
                   email: item.email,
                   user: item.user,
                   is_admin: item.is_admin,
                   created_at: formatDate(item.created_at)
               })
           })
           setRows(rowArray);
           children(false);
        }
    ).catch(error => {
        console.log(error);
        children(false);
        toast.error("Não foi possível carregar os usuários !");
    })
  }

  return (
      <>
       <PageTitle title={"Usuários"} icon={UserIcon} />  

        <CadastrarUsuario postUser={postUser} listUsers={listUsers} loading={children} />

        <br></br>
        <Divider />
        <br></br>

        <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
            <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell align="right">E-mail</TableCell>
                <TableCell align="right">Usuário</TableCell>
                <TableCell align="right">Administrador</TableCell>
                <TableCell align="right">Cadastrado em</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {rows.map((row) => (
                <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell align="right">{row.email}</TableCell>
                <TableCell align="right">{row.user}</TableCell>
                <TableCell align="right">{row.is_admin ? "Sim" : "Não"}</TableCell>
                <TableCell align="right">{row.created_at}</TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    </>
  );
}