import api from './api';

export function updateParcela(id, data){
    return api.patch(`${getURI()}/${id}`, data);
}

export function baixaParcelas(id_contrato, data){
    return api.post(`baixa-parcela/${id_contrato}`, data);
}

export function removerBaixaParcela(id_parcela){
   return api.post(`remove-baixa/${id_parcela}`, {});
}

function getURI(){
    return 'parcela';
}