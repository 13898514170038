const noError = {error: false, message: ""};

export function validEmail(email){
    const regexEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return regexEmail.test(email) 
    ? noError
    : {error: true, message: "Formato de E-mail inválido !"}
}

export function validRequired(value){
    return value === "" 
    ? {error: true, message: "Campo Obrigatório !"} 
    : noError;
}

export function validMinLength(value, length){
    return value.length < length 
    ? {error: true, message: "O campo deve ter no mínimo "+ length + " caracteres !"} 
    : noError;
}

export function compareFields(field1, field2, type = ""){
    return field1 === field2 ? noError : {error: true, message: "Os campos "+type+ " não coicidem !"} 
}