import React, { useState } from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import BaseRelDialog from './components/BaseRelDialog';
import { getRelClientesParcelaBaixas } from '../../services/RelatorioService';
import ReportIcon from '../../assets/icons/report.svg';
function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}
  

export default function Relatorios (props) {

    const {children} = props;
    const [open, setOpen] = useState(false);
    const [report, setReport] = useState("");
    const [title, setTitle] = useState("");
    
    const handleClickOpen = (rel, tit) => {
        setOpen(true);
        setReport(rel);
        setTitle(tit);
    };

    return(
        <>
         <PageTitle title="Relatórios" icon={ReportIcon} />

         <TableContainer component={Paper}>
            <Table  aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>Nome do Relatório</TableCell>
                    <TableCell align="right">Emitir</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                <TableRow key={1}>
                    <TableCell component="th" scope="row">
                        Relatório de Clientes / Parcelas com Baixa
                    </TableCell>
                    <TableCell align="right">
                        <Button onClick={() => handleClickOpen("parcelas_baixa", "Relatório de Clientes / Parcelas com Baixa")} color="primary" variant="outlined" >Emitir <OpenInNewIcon style={{marginLeft: "10px"}} /></Button>
                    </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>

        <BaseRelDialog 
            title={title}
            report={report}
            setOpen={setOpen}
            open={open}
            loading={children}
        />

        </>
    )
}
