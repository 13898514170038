import api from './api';

export function getUsers(){
    return api.get(getURI());
}

export function postUser(data){
    return api.post(getURI(), data);
}

function getURI(){
    return 'user';
}