import React, { useEffect, useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import { useHistory, withRouter } from "react-router-dom";

import useStyles from "./styles";
import logo from "./logo_white.png";

import { Close as CloseIcon } from "@material-ui/icons";
import 'react-toastify/dist/ReactToastify.min.css'; 
import { getResetPassword, recoveryPassword } from "../../services/ResetPasswordService";
import { toast } from "react-toastify";


function RecoverPassword() {

  const classes = useStyles();
  const { location, history } = useHistory();

  // local
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({
    error_password: false, 
    error_confirm_password: false
  });
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    loadTokenRecover()
  }, [])

  const loadTokenRecover = (props) => {
    const divider = location.pathname.split('/');
    const tokenRequest = divider[divider.length - 1];
    getResetPassword(tokenRequest).then(
        response => {
            const data = response.data;
            if(data.data.email) {
                setToken(tokenRequest);
                setEmail(data.data.email);
            }else {
                window.location.href = "/#/login";
            }
        }
    ).catch(error => {
        window.location.href = "/#/login";
    })
  }


  const handlePassword = (e) => {
    const value = e.target.value;
    setPassword(e.target.value);
    let error_password = value.length < 6 ? true : false;
    setError({
        ...error,
        error_password
    })
  }

  const handlePasswordConfirmation = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    let error_confirm_password = value != password ? true: false
    setError({
        ...error,
        error_confirm_password
    })
  }

  const something = (event) => {
    if (event.keyCode === 13) {
        onSubmit()
    }
  }

  const onSubmit = () => {
        const data = {
            token,
            password,
            email,
            password_confirmation: confirmPassword
        }
        setIsLoading(true);
        recoveryPassword(data).then(
            response => {
                const data = response.data;
                if(!data.errors) {
                    toast.success("Senha redefinida com sucesso !");
                    window.location.href = "/#/login";
                }
            }
        );
  }

  
  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>COBRANÇAS</Typography>
      </div>
      <div className={classes.formContainer}>
        <form autoComplete="off" onKeyDown={(e) => something(e)} className={classes.form}>
            <React.Fragment>
              <Typography variant="h3" style={{marginBottom: 35}}>
                  Redefinir senha
              </Typography>

              <div style={{marginTop: "-25px"}}>
                <TextField
                    fullWidth
                    type="password"
                    onChange={handlePassword}
                    id="outlined-error-helper-text"
                    label="Nova senha"
                    required
                    error={error.error_password}
                    value={password}
                    helperText={error.error_password ? "O campo senha deve conter ao menos 6 digitos" : ""}
                />

              <TextField
                    fullWidth
                    type="password"
                    style={{marginTop: 10}}
                    onChange={handlePasswordConfirmation}
                    id="outlined-error-helper-text"
                    label="Confirmar senha"
                    required
                    error={error.error_confirm_password}
                    value={confirmPassword}
                    helperText={error.error_confirm_password ? "Os campos senhas não coicicedem" : ""}
                />
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    onClick={onSubmit}
                    disabled={
                      password.length < 6 || password != confirmPassword
                    }
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Redefinir
                  </Button>
                )}
              </div>
               
              </div>
            </React.Fragment>
        </form>
        <Typography color="primary" className={classes.copyright}>
          © 2020 DSCobranças. All rights reserved.
        </Typography>
      </div>
    
    </Grid>
  );
}


// #############################################################
function CloseButton({ closeToast, className }) {
  return <CloseIcon style={{position: "absolute"}}  className={className} onClick={closeToast} />;
}


export default withRouter(RecoverPassword);
