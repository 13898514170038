import api from './api';

export function getRelClientesParcelaBaixas(data){
    return api.post(`${getURI()}/cliente-parcela-baixa`, data, {
        responseType: "blob"
    });
}

export function getClientsRecibo(data_inicial, data_final) {
    return api.get(`${getURI()}/recibos?data_inicial=${data_inicial}&data_final=${data_final}`);
}

function getURI(){
    return 'relatorio'
}
