import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { MonetizationOn } from '@material-ui/icons';
import PageTitle from '../../../components/PageTitle/PageTitle';
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core';
import DadosFicha from './DadosFicha';
import SendIcon from '@material-ui/icons/Send';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import { getDataClient, patchCliente } from '../../../services/ClienteService';
import MySweetAlert from '../../../components/SweetAlert';
import { changeFicha } from '../../../services/FichaService';
import Debitos from './Debitos';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PrintIcon from '@material-ui/icons/Print';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Recibo from './Recibo';
import RefreshIcon from '@material-ui/icons/Refresh';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { print } from '../../../shared/Utils';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`scrollable-force-tabpanel-${index}`} aria-labelledby={`scrollable-force-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: '10px',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  floatButton: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    paddingRight: '20px',
    paddingBottom: '20px',
  },
}));

export default function ShowClients(props) {
  const { children } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [openWhatsApp, setWhatsapp] = useState(false);
  const [openRecibo, setOpenRecibo] = useState(false);
  const [telefoneWhatsapp, setTelefoneWhatsApp] = useState('');
  const [messageWhatsApp, setMessageWhatsApp] = useState('');
  const [cliente, setCliente] = useState({});
  const [statusFicha, setStatusFicha] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [typeMessage, setTypeMessage] = React.useState('');
  const [dataContratos, setDataContratos] = React.useState([]);
  const [loadingPeriodo, setLoadingPeriodo] = React.useState();
  const [periodoRecibo, setPeriodoRecibo] = React.useState({
    data_inicial: '2020-01-01',
    data_final: moment().format('YYYY-MM-DD'),
  });
  const [statusCobranca, setStatusCobranca] = useState({
    color: '',
    text: '',
  });
  const [sweet, setSweet] = useState({
    show: false,
    type: '',
    title: '',
    action: '',
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getDadosCliente();
  }, []);

  const handleChangePeriodo = (value, attr) => {
    setPeriodoRecibo({ ...periodoRecibo, [attr]: moment(value).format('YYYY-MM-DD') });
  };

  const refreshPeriodo = () => {
    setLoadingPeriodo(true);
    setTimeout(() => {
      setLoadingPeriodo(false);
    }, 300);
  };

  const getDadosCliente = useCallback(() => {
    children(true);
    getDataClient(props.match.params.id)
      .then(response => {
        const clienteData = response.data.data;
        if (response.data.status) {
          setDataContratos(clienteData.contratos);
          loadStatusCobranca(clienteData.status_cobranca);
          setCliente(clienteData);
          setTelefoneWhatsApp(clienteData.telefone);
          clienteData.deleted_at === null ? setStatusFicha('E') : setStatusFicha('S');
        }
        children(false);
      })
      .catch(error => {
        children(false);
        console.log(error);
        //props.history.push('../clients');
      });
  });

  const colorsStatusCobranca = {
    nivel0: '#6e6e6e',
    nivel1: '#6fbf73',
    nivel2: '#ffc107',
    nivel3: '#aa2e25',
    nivel4: '#083c78',
  };

  const loadStatusCobranca = status => {
    switch (status) {
      case 0:
        setStatusCobranca({ color: colorsStatusCobranca.nivel0, text: 'Não cobrado' });
        break;
      case 1:
        setStatusCobranca({ color: colorsStatusCobranca.nivel1, text: 'Cobrado nível 1' });
        break;
      case 2:
        setStatusCobranca({ color: colorsStatusCobranca.nivel2, text: 'Cobrado nível 2' });
        break;
      case 3:
        setStatusCobranca({ color: colorsStatusCobranca.nivel3, text: 'cobrado nível 3' });
        break;
      case 4:
        setStatusCobranca({ color: colorsStatusCobranca.nivel4, text: 'Em Negociação' });
        break;
      default:
        alert('Não deu');
    }
  };

  const fastMessage = type => {
    setTypeMessage(type);

    switch (type) {
      case 1:
        setMessageWhatsApp(
          'Olá ' +
            cliente.nome_cliente +
            ', Somos da DS Cobranças. Sua dívida da Atlanta pode ser renegociada. Os telefones para contato são: (62) 9 9455-4244 Yan. Evite mais juros. Fale com nossa equipe hoje mesmo!',
        );
        break;
      case 2:
        setMessageWhatsApp(
          'Olá ' +
            cliente.nome_cliente +
            ', Nós da DS Cobranças temos uma proposta para negociar seu débito. Entre em contato conosco e retire a restrição do seu nome do SPC. Telefones: (62) 9 9455-4244 Yan',
        );
        break;
      case 3:
        setMessageWhatsApp(
          cliente.nome_cliente +
            ', Constatamos em nosso sistema um débito até o presente momento não pago. Entre em contato para regularização de seu débito e liberação perante SPC. (62) 9 9455-4244 Yan',
        );
        break;
      case 4:
        setMessageWhatsApp('Olá ' + cliente.nome_cliente + ', Nós da DS Cobranças. Informamos que seu boleto vence hoje, não deixe de efetuar o pagamento, evite mais juros e correções. Obrigado!');
        break;
      default:
        setMessageWhatsApp('');
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const sendMessageWhatsApp = () => {
    const url = `https://api.whatsapp.com/send?phone=55${telefoneWhatsapp}&text=${messageWhatsApp}`;

    if (telefoneWhatsapp.length <= 9) {
      toast.error('Preencha o telefone do cliente corretamente');
    } else {
      window.open(url, '_blank');
      setWhatsapp(false);
    }
  };

  const changeStatusFicha = () => {
    const newStatusFicha = statusFicha === 'E' ? 'S' : 'E';
    const dataStatus = {
      id_cliente: cliente.id_cliente,
      status_ficha: newStatusFicha,
    };
    children(true);
    changeFicha(dataStatus)
      .then(response => {
        if (response.data.status) {
          setStatusFicha(newStatusFicha);
          toast.success('Status da Ficha alterado com sucesso !');
          setSweet({
            show: false,
            type: '',
            title: '',
            action: '',
          });
        }
        children(false);
      })
      .catch(error => {
        children(false);
      });
  };

  const changeStatusCobranca = status => {
    const data = {
      status_cobranca: status,
    };
    children(true);
    patchCliente(cliente.id_cliente, data)
      .then(response => {
        if (response.data.status) {
          loadStatusCobranca(status);
          setCliente(response.data.data);
          children(false);
        }
      })
      .catch(error => {
        console.log(error);
        children(false);
      });
  };

  return (
    <div className={classes.root}>
      <Button
        onClick={() => {
          props.history.push('../../../app/clients');
        }}
        style={{ float: 'right', marginBottom: '15px' }}
        color="primary"
        variant="outlined"
      >
        <KeyboardBackspaceIcon /> Voltar
      </Button>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Hidden xlUp xsDown smDown>
          <PageTitle title={cliente.nome_cliente} />
        </Hidden>
      </div>

      <AppBar position="static" color="default">
        <Tabs value={value} onChange={handleChange} variant="fullWidth" scrollButtons="on" indicatorColor="primary" textColor="primary" aria-label="scrollable force tabs example">
          <Tab label="Dados da Ficha" icon={<PersonPinIcon />} {...a11yProps(0)} />
          <Tab label="Debitos do Cliente" icon={<MonetizationOn />} {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {statusFicha === 'E' ? (
          <Chip
            style={{ marginBottom: '20px' }}
            icon={<CheckIcon />}
            label="Ficha Ativa"
            clickable
            onClick={() =>
              setSweet({
                show: true,
                type: 'warning',
                title: 'Você está prestes a mudar o status da ficha para Inativo, deseja continuar ?',
                action: changeStatusFicha,
              })
            }
            color="primary"
          />
        ) : statusFicha === 'S' ? (
          <Chip
            style={{ marginBottom: '20px' }}
            icon={<CloseIcon />}
            label="Ficha Inativa"
            clickable
            onClick={() =>
              setSweet({
                show: true,
                type: 'warning',
                title: 'Você está prestes a mudar o status da ficha para Ativo, deseja continuar ?',
                action: changeStatusFicha,
              })
            }
            color="secondary"
          />
        ) : (
          ''
        )}
        <Button style={{ float: 'right', background: statusCobranca.color, color: 'white' }} aria-describedby={id} variant="contained" onClick={handleClick}>
          {statusCobranca.text}
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <div style={{ padding: '20px' }}>
            <Typography>Marcar como</Typography>
            <List component="nav" aria-label="main mailbox folders">
              <ListItem button onClick={() => changeStatusCobranca(0)}>
                <ListItemIcon>
                  <FiberManualRecordIcon />
                </ListItemIcon>
                <ListItemText primary="Não Cobrado" />
              </ListItem>
              <ListItem button onClick={() => changeStatusCobranca(1)}>
                <ListItemIcon>
                  <FiberManualRecordIcon style={{ color: colorsStatusCobranca.nivel1 }} />
                </ListItemIcon>
                <ListItemText primary="Cobrado Nível 1" />
              </ListItem>
              <ListItem button onClick={() => changeStatusCobranca(2)}>
                <ListItemIcon>
                  <FiberManualRecordIcon style={{ color: colorsStatusCobranca.nivel2 }} />
                </ListItemIcon>
                <ListItemText primary="Cobrado Nível 2" />
              </ListItem>
              <ListItem button onClick={() => changeStatusCobranca(3)}>
                <ListItemIcon>
                  <FiberManualRecordIcon style={{ color: colorsStatusCobranca.nivel3 }} />
                </ListItemIcon>
                <ListItemText primary="Cobrado Nível 3" />
              </ListItem>
              <ListItem button onClick={() => changeStatusCobranca(4)}>
                <ListItemIcon>
                  <FiberManualRecordIcon style={{ color: colorsStatusCobranca.nivel4 }} />
                </ListItemIcon>
                <ListItemText primary="Em negociação" />
              </ListItem>
            </List>
          </div>
        </Popover>

        <DadosFicha cliente={cliente} getDadosCliente={getDadosCliente} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {statusFicha === 'E' ? (
          <Chip
            style={{ marginBottom: '20px' }}
            icon={<CheckIcon />}
            label="Ficha Ativa"
            clickable
            onClick={() =>
              setSweet({
                show: true,
                type: 'warning',
                title: 'Você está prestes a mudar o status da ficha para Inativo, deseja continuar ?',
                action: changeStatusFicha,
              })
            }
            color="primary"
          />
        ) : (
          <Chip
            style={{ marginBottom: '20px' }}
            icon={<CloseIcon />}
            label="Ficha Inativa"
            clickable
            onClick={() =>
              setSweet({
                show: true,
                type: 'warning',
                title: 'Você está prestes a mudar o status da ficha para Ativo, deseja continuar ?',
                action: changeStatusFicha,
              })
            }
            color="secondary"
          />
        )}

        <b style={{ float: 'right' }}>
          <Button onClick={() => setOpenRecibo(true)} style={{ marginRight: 10 }} color="primary" variant="outlined">
            <ReceiptIcon />
            <Hidden xsDown>
              <span style={{ marginLeft: 10 }}> Recibo</span>
            </Hidden>
          </Button>
        </b>

        <Debitos setDataContratosRecibo={setDataContratos} history={props.history} match={props.match} children={children} />
      </TabPanel>

      <span className={classes.floatButton}>
        <Tooltip title={`Mandar mensagem para ${cliente.nome_cliente}`} aria-label="WhatsApp">
          <Fab onClick={() => setWhatsapp(true)} style={{ backgroundColor: '#4dc362', color: 'white' }} aria-label="add">
            <WhatsAppIcon />
          </Fab>
        </Tooltip>
      </span>

      <Dialog onClose={() => setOpenRecibo(false)} aria-labelledby="customized-dialog-title" open={openRecibo}>
        <DialogTitle style={{ textAlign: 'center' }} id="customized-dialog-title" onClose={() => setWhatsapp(false)}>
          Recibo do cliente {cliente.nome_cliente}
          <span style={{ float: 'rigth' }}>
            {' '}
            <ReceiptIcon />{' '}
          </span>
        </DialogTitle>
        <DialogContent style={{ overflowX: 'hidden' }} dividers>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  id="date-picker-inline"
                  label="Data Inicial"
                  required
                  value={moment(periodoRecibo.data_inicial).add('days', 1).format('YYYY-MM-DD')}
                  onChange={value => handleChangePeriodo(value, 'data_inicial')}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={5}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  id="date-picker-inline"
                  label="Data Final"
                  required
                  value={moment(periodoRecibo.data_final).add('days', 1).format('YYYY-MM-DD')}
                  onChange={value => handleChangePeriodo(value, 'data_final')}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={2}>
              <IconButton onClick={refreshPeriodo} color="primary" aria-label="delete" size="large">
                <RefreshIcon fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>

          {loadingPeriodo ? (
            <div style={{ textAlign: 'center' }}>
              {' '}
              <CircularProgress />{' '}
            </div>
          ) : (
            <div style={{ display: 'flex', flex: '1', flexWrap: 'wrap' }} id={'recibo_cliente_' + cliente.nome_cliente}>
              <Recibo cliente={cliente} data_inicial={periodoRecibo.data_inicial} data_final={periodoRecibo.data_final} />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRecibo(false)} variant="outlined" color="danger">
            Fechar
          </Button>
          <Button style={{ float: 'right' }} onClick={() => print('recibo_cliente_' + cliente.nome_cliente)} color="primary" variant="outlined">
            <PrintIcon />
            <Hidden xsDown>
              <span style={{ marginLeft: 10 }}> Imprimir</span>
            </Hidden>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog onClose={() => setWhatsapp(false)} aria-labelledby="customized-dialog-title" open={openWhatsApp}>
        <DialogTitle id="customized-dialog-title" onClose={() => setWhatsapp(false)}>
          Enviar mensagem para {cliente.nome_cliente}
          <Button onClick={() => setWhatsapp(false)} style={{ float: 'right' }}>
            <CloseIcon />
          </Button>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing="3">
            <Grid item xs={12} md={12}>
              <Typography variant={'h7'}>Confirme o número de telefone com o DDD antes de mandar a mensagem.</Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">Tipo de Mensagem</InputLabel>
                <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={typeMessage} onChange={e => fastMessage(e.target.value)} label="Tipo de Mensagem">
                  <MenuItem value="">
                    <em>Nenhuma</em>
                  </MenuItem>
                  <MenuItem value={1}>
                    <FiberManualRecordIcon style={{ color: colorsStatusCobranca.nivel1, marginRight: 4 }} /> Mensagem nível 1
                  </MenuItem>
                  <MenuItem value={2}>
                    <FiberManualRecordIcon style={{ color: colorsStatusCobranca.nivel2, marginRight: 4 }} /> Mensagem nível 2
                  </MenuItem>
                  <MenuItem value={3}>
                    <FiberManualRecordIcon style={{ color: colorsStatusCobranca.nivel3, marginRight: 4 }} /> Mensagem nível 3
                  </MenuItem>
                  <MenuItem value={4}>
                    <ErrorOutlineIcon style={{ color: colorsStatusCobranca.nivel2, marginRight: 4 }} /> Mensagem de Lembrete
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-amount">Telefone</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  value={telefoneWhatsapp}
                  onChange={e => setTelefoneWhatsApp(e.target.value.replace(/\D/g, ''))}
                  startAdornment={<InputAdornment position="start">+55</InputAdornment>}
                  labelWidth={60}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
              <TextField fullWidth id="outlined-basic" label="Mensagem" value={messageWhatsApp} onChange={e => setMessageWhatsApp(e.target.value)} multiline rows={3} rowsMax={4} variant="outlined" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => sendMessageWhatsApp()} variant="outlined" style={{ backgroundColor: '#27d367', color: 'white', borderColor: '#27d367' }}>
            Enviar <SendIcon style={{ marginLeft: '10px' }} />
          </Button>
        </DialogActions>
      </Dialog>

      {sweet.show ? <MySweetAlert type={sweet.type} title={sweet.title} setOpenSweet={setSweet} id={''} action={sweet.action} showCancel={true} /> : ''}
    </div>
  );
}
