import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    floatButton: {
        position: "fixed",
        bottom: 0,
        right: 0,
        paddingRight: "20px",
        paddingBottom: "20px"
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
      },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));
