import api from './api';

export function listCliente(setListClientes){
    return api.get(getURI()+'-list')
    .then(response => {
        const data = response.data;
        setListClientes(data);
    }).catch(error => {
        console.log(error);
    });
}

export function getClientes(show_deleted){
    return api.get(`${getURI()}?show_deleted=${show_deleted}&allResults=true`);
}

export function getDataClient(id){
    return api.get(`${getURI()}/${id}`);
}

export function patchCliente(id_cliente, data){
    return api.patch(`${getURI()}/${id_cliente}`, data);
}

function getURI(){
    return 'cliente';
}