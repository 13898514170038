import React, { useState, useEffect } from 'react';
import FullCalendar, { disableCursor, enableCursor } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import TimeGrid from '@fullcalendar/timegrid';
import TimeLine from '@fullcalendar/timeline';
import Lists from '@fullcalendar/list';
import Bootstrap from '@fullcalendar/bootstrap';
import PageTitle from '../../components/PageTitle/PageTitle';
import interactionPlugin from '@fullcalendar/interaction';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-toastify/dist/ReactToastify.min.css';
import AcordoIcon from '../../assets/icons/acordo.svg';

import './Calendar.css';
import { getAcordos, setAcordos } from '../../services/AcordoService';
import { hideLoader, showLoader, useLoaderDispatch } from '../../context/LoaderContext';
import CadastraAcordo from './components/CadastraAcordo';
import AlteraAcordo from './components/AlteraAcordo';

export default function Calendar(props) {
  const { children } = props;

  const [dataCalendar, setDataCalendar] = useState([]);
  const [open, setopen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [dateSelected, setdateSelectd] = useState('');
  const [data_acordo, setDataAcordo] = useState('');
  const [event, setEvent] = useState({});
  const loaderDispatch = useLoaderDispatch();

  useEffect(() => {
    showLoader(loaderDispatch);
    listAcordos();
  }, []);

  const listAcordos = () => {
    children(true);
    getAcordos().then(response => {
      const data = response.data.data;
      const dataArray = [];
      data.map(item => {
        dataArray.push({
          id: item.id_acordo,
          title: item.nome_cliente,
          date: item.data_acordo,
          color: item.color,
        });
      });
      setDataCalendar(dataArray);
      children(false);
    });
  };

  const handleDateClick = e => {
    const dateEvent = e.dateStr;
    const dateEventFormated = dateEvent.substring(8, 10) + '/' + dateEvent.substring(5, 7) + '/' + dateEvent.substring(0, 4);
    setopen(true);
    setdateSelectd(dateEventFormated);
    setDataAcordo(e.dateStr);
  };

  const handleEventClick = e => {
    setEvent(e.event);
    setEditOpen(true);
  };

  const checkDate = dateSelect => {
    var date = new Date();
    var dateToday = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    var today = new Date(dateToday);
    var days = new Date(dateSelect) - today;

    if (days >= 0 || days == -10800000) {
      return true;
    }
    return false;
  };

  return (
    <>
      <PageTitle title="Meus Acordos" icon={AcordoIcon} />
      <div style={{ backgroundColor: 'white', paddingTop: '25px', paddingRight: '10px', paddingLeft: '10px', paddingBottom: '15px' }}>
        <FullCalendar
          displayEventTime={false}
          eventOrder={'start,-duration,allDay,title'}
          navLinkWeekClick={true}
          fixedWeekCount={false}
          selectable={true}
          editable={false}
          buttonText={{
            prevYear: '&nbsp;&lt;&lt;&nbsp;',
            nextYear: '&nbsp;&gt;&gt;&nbsp;',
            today: 'Hoje',
            month: 'Mês',
            week: 'Semana',
            day: 'Dia',
            listMonth: 'Lista Mensal',
          }}
          locale={'pt-br'}
          headerToolbar={
            window.screen.width > 512
              ? {
                  left: 'prev,next today',
                  center: 'title',
                  right: 'dayGridMonth,listMonth,dayGridWeek',
                }
              : {
                  left: 'prev,next today',
                  right: 'listMonth,dayGridWeek',
                }
          }
          nowIndicator={false}
          handleWindowResize={true}
          allDay={false}
          plugins={[dayGridPlugin, interactionPlugin, Bootstrap, TimeGrid, Lists, TimeLine]}
          dateClick={e => (checkDate(e.dateStr) ? handleDateClick(e) : '')}
          events={dataCalendar}
          eventClick={event => handleEventClick(event)}
          eventContent={renderEventContent}
          schedulerLicenseKey={'CC-Attribution-NonCommercial-NoDerivatives'}
        />
      </div>

      {open && (
        <CadastraAcordo open={open} setopen={setopen} listAcordos={listAcordos} dataCalendar={dataCalendar} setDataCalendar={setDataCalendar} data_acordo={data_acordo} dateSelected={dateSelected} />
      )}

      {editOpen && <AlteraAcordo setLoading={children} dateSelected={dateSelected} listAcordos={listAcordos} editOpen={editOpen} setEditOpen={setEditOpen} event={event} />}
    </>
  );
}

function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  );
}
