import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import RelParcelasBaixas from './RelParcelasBaixas';
import { formatDate, transformDate } from '../../../shared/Utils';
import { getRelClientesParcelaBaixas } from '../../../services/RelatorioService';
import { toast } from 'react-toastify';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function BaseRelDialog(props) {

    const { open, setOpen, title, report, loading } = props;
    const [dataRel, setDataRel] = useState({});

    const handleClose = () => {
        setOpen(false);
    };

    const exportRelatorio = () => {

      let data = {
          "data_inicial" : transformDate(dataRel.data_inicial),
          "data_final" : transformDate(dataRel.data_final),
          "type": dataRel.type
      }

      loading(true);
      getRelClientesParcelaBaixas(data).then(
          response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            if(data.type == "excel"){
              link.setAttribute('download', 'RelatorioClientesParcelasBaixa-'+data.data_inicial+'-a-'+data.data_final+'.xlsx');
            }else if(data.type == "pdf"){
              link.setAttribute('download', 'RelatorioClientesParcelasBaixa-'+data.data_inicial+'-a-'+data.data_final+'.pdf');
            }
            document.body.appendChild(link);
            link.click();
            loading(false);
          }
      ).catch(error => {
          console.log(error);
          toast.error("Não foi possível gerar o relatório !");
      })
  }


    return (
        <div>
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {title}
            </DialogTitle>
            <DialogContent dividers>
                {
                    report === "parcelas_baixa" ? (
                        <RelParcelasBaixas setDataRel={setDataRel} report={report} />
                    ): ""
                }
            </DialogContent>
            <DialogActions>
            <Button autoFocus onClick={exportRelatorio} variant="outlined" color="primary">
                Emitir <CloudDownloadIcon  style={{marginLeft: "10px"}} />
            </Button>
            </DialogActions>
        </Dialog>
        </div>
  );
}