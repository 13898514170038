import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import logo from '../../login/logo.png';
import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import { betweenData, calculoJuros, formatAmount, formatCPF, getValorFinal } from '../../../shared/Utils';

export default function Recibo(props) {
  const { cliente, data_inicial, data_final } = props;
  const dataContratos = cliente.contratos;
  const [contratos, setContratos] = React.useState([]);
  const [periodo, setPeriodo] = React.useState({
    data_inicial,
    data_final,
  });

  const classes = useStyles();
  moment.locale('pt-br');

  React.useEffect(() => {
    processaContratos();
  }, []);

  const processaContratos = () => {
    const newDataContratos = [];
    dataContratos.map(contrato => {
      if (checkContratoTemParcelaPaga(contrato.parcelas)) {
        newDataContratos.push(contrato);
      }
    });
    setContratos(newDataContratos);
  };

  const checkContratoTemParcelaPaga = parcelas => {
    let tem_parcela_paga = false;
    parcelas.map(parcela => {
      const valor_total = getValorFinal(parcela.dt_vencimento, parcela.valor_vencimento, parcela.soma_valor, parcela.data_pagamento);
      if (parcela.paga && valor_total > 0) {
        tem_parcela_paga = true;
      }
    });
    return tem_parcela_paga;
  };

  const resultadoJurosMenosAjuste = parcela => {
    const juros = calculoJuros(parcela.dt_vencimento, parcela.valor_vencimento, parcela.data_pagamento);
    return juros + parseFloat(parcela.soma_valor);
  };

  return (
    <div className={classes.containerRecibo}>
      <Grid container spacing={1}>
        <div className={classes.dividerDotted}></div>

        <Grid style={{ marginLeft: -10 }} item xs={2}>
          <img width={60} src={logo} />
        </Grid>

        <Grid item xs={7}>
          <div style={{ marginTop: 8 }}>
            <span className={classes.cnpj}> DS Cobranças </span> <br></br>
            <span className={classes.cnpj}> CNPJ 21.565.977.0001-26 </span>
          </div>
        </Grid>

        <Grid item xs={3}>
          <div className={classes.headerDateTime}>
            <div className={classes.textRight}>
              <span>{moment().format('DD/MM/YYYY')}</span>
            </div>
            <div className={classes.textRight}>
              <span>{moment().format('H:m')}</span>
            </div>
          </div>
        </Grid>

        <Grid item xs={12}>
          <Typography color={'black'} variant={'h7'}>
            <b>Cliente</b>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <span className={classes.texts}>
            <b>Nome</b>
            <br />
            {cliente.nome_cliente} - {cliente.codigo_cliente}
          </span>
        </Grid>

        <Grid item xs={12}>
          <span className={classes.texts}>
            <b>CPF</b>
            <br />
            {formatCPF(cliente.cpf)}
          </span>
        </Grid>

        {contratos.map(contrato => {
          let valor_total_contrato = 0;

          return (
            <>
              <Grid item xs={12}>
                <Typography color={'black'} variant={'h7'}>
                  <b> Contrato {contrato.numero_documento}</b>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <table style={{ width: '100%' }}>
                  <tr className={classes.texts}>
                    <th align="left">Parcela</th>
                    <th align="right">Valor</th>
                    <th align="right">Juros</th>
                    <th align="right">Total</th>
                  </tr>

                  {contrato.parcelas.map(parcela => {
                    const valor_total = getValorFinal(parcela.dt_vencimento, parcela.valor_vencimento, parcela.soma_valor, parcela.data_pagamento);
                    if (parcela.paga && valor_total > 0 && betweenData(parcela.data_pagamento, periodo.data_inicial, periodo.data_final)) {
                      valor_total_contrato += valor_total;
                      return (
                        <tr className={classes.texts}>
                          <td align="left">
                            {' '}
                            {parcela.ordem_documento}/{parcela.qtd_documento}
                          </td>
                          <td align="right">{formatAmount(parseFloat(parcela.valor_vencimento))}</td>
                          <td align="right">{formatAmount(resultadoJurosMenosAjuste(parcela))}</td>
                          <td align="right">{formatAmount(valor_total)} </td>
                        </tr>
                      );
                    }
                  })}

                  <tr className={classes.texts}>
                    <td align="left"></td>
                    <td align="right"></td>
                    <td align="right">
                      <b>Valor Total</b>
                    </td>
                    <td align="right">
                      <b>{formatAmount(valor_total_contrato)}</b>
                    </td>
                  </tr>
                </table>
              </Grid>
            </>
          );
        })}

        <div style={{ marginTop: 15 }} className={classes.dividerDotted}></div>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles(theme =>
  createStyles({
    containerRecibo: {
      display: 'flex',
      borderTopLeftRadius: 12,
      borderBottomRightRadius: 12,
      borderColor: '#F2F2F2',
      maxWidth: '15cm',
      padding: 20,
    },
    textRight: {
      textAlign: 'right',
    },
    headerImg: {
      float: 'left',
      right: 20,
    },
    headerDateTime: {
      float: 'right',
      fontSize: 12,
      marginTop: 10,
      color: '#56595B',
      opacity: 0.8,
    },
    dividerDotted: {
      paddingLeft: 10,
      borderWidth: 0.5,
      borderStyle: 'dashed',
      borderRadius: 0.1,
      borderColor: '#D1D1D1',
      width: '100%',
    },
    texts: {
      fontSize: 11,
    },
    cnpj: {
      fontSize: 12,
      fontWeight: 'bold',
    },
  }),
);
