import React, { useState } from "react";
import {
  CircularProgress,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";

import useStyles from "./styles";

import logoBlack from "./logo.png";
import {SendLinkReset} from "../../services/ResetPasswordService";
import { toast } from "react-toastify";

export default function ForgotPassword() {

    var classes = useStyles();

    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const changeEmail = (value) => {
        setEmail(value);
        if(value === ""){
            setError(true);
        }
    }   

    const keyEnter = (event) => {
      if (event.keyCode === 13) {
        sendReset()
      }
    }

    const sendReset = () => {
      setIsLoading(true);
      SendLinkReset(email).then(
        response => {
            toast.success("Foi enviado um link de redefinição para seu e-mail !");
            setEmail("");
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })
    }

    return (
        <React.Fragment>
              <Typography variant="h1" className={classes.greeting}>
                <img src={logoBlack} alt="logoBlack" className={classes.logotypeImage} />
              </Typography>
              <Typography variant="h2" className={classes.subGreeting}>
                Recuperar Senha
              </Typography>
            
            <form  onKeyDown={(e) => keyEnter(e)} autoComplete="off">
                <TextField
                    fullWidth
                    id="outlined-error-helper-text"
                    label="E-mail"
                    required
                    error={error}
                    defaultValue={email}
                    value={email}
                    onChange={(event) => changeEmail(event.target.value)}
                    helperText={error ? "Preencha o campo E-mail" : ""}
                />
            </form>
           
              
                <div className={classes.creatingButtonContainer}>
                {isLoading ? (
                  <CircularProgress size={26} />
                ) : (
                  <Button
                    onClick={sendReset}
                    disabled={email.length === 0}
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.createAccountButton}
                  >
                    Recuperar Senha
                  </Button>
                )}
              </div>
           
            </React.Fragment>
    )
}
