import React, { useEffect, useState } from 'react';
import EmprestimoIcon from '../../assets/icons/emprestimo.svg';
import PageTitle from '../../components/PageTitle/PageTitle';
import { CardActionArea, Fab, Tooltip, Typography } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { Card, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { formatAmount } from '../../shared/Utils';
import { CardHeader } from '@material-ui/core';
import moment from 'moment';
import useStylesGeneric from "../../shared/Styles";
import ModalEmprestimo from './components/ModalEmprestimo';
import VerEmprestimo from './components/VerEmprestimo';
import Alert from '@material-ui/lab/Alert';
import EmprestimoService from '../../services/EmprestimoService';

const useStyles = makeStyles({
    root: {
      maxWidth: 345
    },
    media: {
      height: 140,
    },
    margin: {
        margin: "10px 2px 5px "
    },
    actions: {
        float: "right",
        position: "relative"
    },
  });

export default function Emprestimos(props) {

    const { children } = props;

    const [dataEmprestimos, setDataEmprestimos] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openEmprestimo, setOpenEmprestimo] = useState(false);
    const classes = useStyles();
    const Styles = useStylesGeneric();
    const [idEmprestimo, setIdEmprestimo] = useState(null);

    useEffect(() => {
        listEmprestimos();
    }, []);

    const listEmprestimos = () => {
        children(true);
        EmprestimoService.list().then(
            response => {
                const data = response.data.data;
                setDataEmprestimos(data);
                children(false);
            }
        ).catch(error => {
            children(false);
        })
    } 

    const handleOpenEmprestimo = (id) => {
        setIdEmprestimo(id);
        setOpenEmprestimo(true);
    }

    const handleCloseEmprestimo = () => {
        setOpenEmprestimo(false);
        setIdEmprestimo(null);
    }

    const handleCloseModal = () => {
        setIdEmprestimo(null);
        setOpenModal(false);
    }

    const handleOpenEditEmprestimo = (id) => {
        setIdEmprestimo(id);
        setOpenModal(true);
    }

    const getCountTotalParcelas = (dataParcelas) => {
        dataParcelas = JSON.parse(dataParcelas);
        let qtd_pagas = 0;
        dataParcelas.map(item => {
            if(item.paga) qtd_pagas += 1;
        });
        return `${qtd_pagas} de ${dataParcelas.length} parcelas pagas` 
    } 

    return (
        <div>
            <PageTitle title="Emprestimos" icon={EmprestimoIcon} />

            <Grid container spacing={3}>
                {
                    dataEmprestimos.map(item => (
                        <Grid item xs={12} sm={4} md={3} lg={3} xl={2} >
                                <Card className={classes.root}>
                                    <CardHeader
                                        title={moment(item.data_emprestimo).format('DD/MM/YYYY')}
                                        subheader={
                                            <Typography variant="h7" component="h5" component="p">
                                                {getCountTotalParcelas(item.dados_parcelas)}
                                            </Typography>
                                        }
                                        action={
                                            <Fab onClick={() => handleOpenEditEmprestimo(item.id_emprestimo)} size="small" color="primary" aria-label="add" className={classes.margin}>
                                                <EditIcon />
                                            </Fab>
                                        }
                                    />
                                     <Tooltip title="Clique para visualizar os detalhes">
                                        <CardActionArea style={{backgroundColor: "#f3f5ff"}} onClick={() => {handleOpenEmprestimo(item.id_emprestimo)}}>
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="h2">
                                                    {item.nome}
                                                </Typography>
                                                <Typography variant="h6" component="h5" component="p">
                                                    {formatAmount(parseFloat(item.valor))}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Tooltip>
                                </Card>
                            
                        </Grid>
                    ))
                }

                {
                    dataEmprestimos.length === 0 && (
                        <Alert style={{width: "100%", backgroundColor: "#083c788f"}} variant="filled" severity="info">
                            No momento não tem nenhum emprestimo ativo !
                        </Alert>
                    )
                }
            </Grid>

            <span className={Styles.floatButton}>
                <Tooltip title="Novo emprestimo" aria-label="Novo emprestimo">
                    <Fab onClick={() => setOpenModal(true)} color="primary" aria-label="add">
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </span>

            {
                openModal && (
                    <ModalEmprestimo 
                        handleClose={handleCloseModal}
                        idEmprestimo={idEmprestimo}
                        listEmprestimos={listEmprestimos}
                        loader={children}
                    />
                )
            }

            {
                openEmprestimo && (
                    <VerEmprestimo
                        handleClose={handleCloseEmprestimo}
                        idEmprestimo={idEmprestimo}
                        listEmprestimos={listEmprestimos}
                        loader={children}
                    />
                )
            }



        </div>
        
    )
}