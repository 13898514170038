import React, { useState, useEffect } from 'react';
import { Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Select, Slide, TextField } from '@material-ui/core';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import FaceIcon from '@material-ui/icons/Face';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Check from '@material-ui/icons/Check';
import { getAcordo, setStatusPag, deleteAcordo, updateAcordo } from '../../../services/AcordoService';
import { formatDate } from '../../../shared/Utils';
import MySweetAlert from '../../../components/SweetAlert';
import { toast } from 'react-toastify';
import { DeleteOutline } from '@material-ui/icons';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlteraAcordo(props) {
  const { setEditOpen, editOpen, listAcordos, event, setLoading } = props;
  const [dadosAcordo, setDadosAcordo] = useState({});
  const [dadosContrato, setDadosContrato] = useState({});
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [anotacoes, setAnotacoes] = useState('');
  const [sweet, setSweet] = useState({
    show: false,
    type: '',
    title: '',
    action: '',
  });

  const [isLoading, setisLoading] = useState(false);

  const handleEditCancel = () => {
    setEditOpen(false);
  };

  useEffect(() => {
    getDadosAcordo();
  }, []);

  const onEditorStateChange = e => {
    setEditorState(e);
    const convertedValue = convertToRaw(e.getCurrentContent());
    setAnotacoes(JSON.stringify(convertedValue));
  };

  const getDadosAcordo = () => {
    setLoading(true);
    getAcordo(event.id)
      .then(response => {
        const responseData = response.data.data;
        setDadosAcordo(responseData);
        setDadosContrato(responseData.contrato);
        setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(responseData.anotacoes))));
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  };

  const changeStatusPag = id => {
    setStatusPag(id)
      .then(response => {
        toast.success('Status alterado com sucesso !');
        getDadosAcordo();
        listAcordos();
        setSweet({
          show: false,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  const alterarDados = id => {
    setisLoading(true);
    const data = {
      anotacoes,
    };
    updateAcordo(id, data)
      .then(response => {
        const responseData = response.data.data;
        console.log(responseData);
        if (response.data.status) {
          toast.success('Status alterado com sucesso !');
        }
        setisLoading(false);
      })
      .catch(error => {
        console.log(error);
        setisLoading(false);
      });
  };

  const destroyAcordo = id => {
    setLoading(true);
    deleteAcordo(id)
      .then(response => {
        toast.success('Acordo deletado com sucesso !');
        listAcordos();
        setSweet({
          show: false,
        });
        setEditOpen(false);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <>
      <Dialog TransitionComponent={Transition} disableBackdropClick maxWidth={'sm'} disableEscapeKeyDown aria-labelledby="confirmation-dialog-title" open={editOpen}>
        <DialogTitle id="confirmation-dialog-title">
          Acordo {dadosAcordo.nome_cliente}
          <span style={{ float: 'right' }}>
            {formatDate(dadosAcordo.data_acordo)}
            <Button
              onClick={() =>
                setSweet({
                  show: true,
                  type: 'danger',
                  title: `Deseja realmente excluir esse acordo ?`,
                  action: destroyAcordo,
                })
              }
              color="secondary"
            >
              <DeleteOutline />
            </Button>
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <h4 style={{ float: 'right' }}>
                <Chip style={{ marginLeft: '5px' }} icon={<FaceIcon />} label={dadosAcordo.usuario} clickable color="primary" />
                {dadosAcordo.pago ? (
                  <Chip
                    onClick={() =>
                      setSweet({
                        show: true,
                        type: 'warning',
                        title: `Deseja alterar o status do pagamento para ${dadosAcordo.pago ? 'Pendente' : 'Pago'} ?`,
                        action: changeStatusPag,
                      })
                    }
                    style={{ marginLeft: '5px' }}
                    icon={<Check />}
                    label="Pago"
                    clickable
                    color="primary"
                  />
                ) : (
                  <Chip
                    onClick={() =>
                      setSweet({
                        show: true,
                        type: 'warning',
                        title: `Deseja alterar o status do pagamento para ${dadosAcordo.pago ? 'Pendente' : 'Pago'} ?`,
                        action: changeStatusPag,
                      })
                    }
                    style={{ marginLeft: '5px' }}
                    icon={<ErrorOutlineIcon />}
                    label="Pendente"
                    clickable
                    color="secondary"
                  />
                )}
              </h4>
            </Grid>

            <Grid item xs={12} md={6}>
              <h4>
                Numero do Documento: {dadosContrato.numero_documento}
                <br></br>
                Realizado em: {formatDate(dadosAcordo.created_at)}
                <br></br>
                Ultima alteração em: <span>{formatDate(dadosAcordo.updated_at)}</span>
              </h4>
            </Grid>

            <Grid item xs={12}>
              <TextField
                style={{ marginTop: '-15px' }}
                fullWidth
                id="outlined-basic"
                label="Nome da Loja"
                InputProps={{
                  readOnly: true,
                }}
                defaultValue=" "
                value={dadosContrato.loja}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <Editor
                editorState={editorState}
                wrapperClassName="rich-editor demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={e => onEditorStateChange(e)}
                placeholder=" Anotações.."
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={() => handleEditCancel()} autoFocus color="secondary">
            Cancelar
          </Button>
          <Button disabled={false} onClick={() => alterarDados(dadosAcordo.id_acordo)} variant="outlined" color="primary">
            {isLoading ? <CircularProgress size={26} /> : 'Alterar'}
          </Button>
        </DialogActions>
      </Dialog>

      {sweet.show ? <MySweetAlert type={sweet.type} title={sweet.title} setOpenSweet={setSweet} id={dadosAcordo.id_acordo} action={sweet.action} showCancel={true} /> : ''}
    </>
  );
}
