import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { validMinLength, validEmail, validRequired, compareFields } from '../../../services/Validators';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function CadastrarUsuario(props) {

  const { postUser, listUsers, loading } = props;  
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorName, setErrorName] = useState({error:false, message: ""});
  const [errorEmail, setErrorEmail] = useState({error:false, message: ""});
  const [errorUser, setErrorUser] = useState({error:false, message: ""});
  const [errorPassword, setErrorPassword] = useState({error:false, message: ""});
  const [errorConfirmPassword, setErrorConfirmPassword] = useState({ error: false, message: ""});

  const validFields = () => {
      if(validRequired(name).error) {
        setErrorName(validRequired(name)) 
        return false;
      } 
      if(validEmail(email).error) {
        setErrorEmail(validEmail(email)); 
        return false;
      } 
      if(validMinLength(user, 3).error) {
        setErrorUser(validMinLength(user, 3)); 
        return false;
      }
      if(validMinLength(password, 6).error) {
        setErrorPassword(validMinLength(password, 3)); 
        return false;
      }
      if(compareFields(password, confirmPassword, "Confirmar Senha").error) {
        setErrorConfirmPassword(compareFields(password, confirmPassword, "Confirmar Senha")); 
        return false;
      }
      return true;
  }

  const newUser = () => {
    if(validFields()){
        const data = {
            name: name,
            email: email,
            user: user,
            password: password,
            is_admin: isAdmin,
            password_confirmation: confirmPassword
        };
        loading(true);
        postUser(data).then(
            response => {
                if(response.data.status){
                    toast.success("Usuário cadastrado com sucesso !");
                    listUsers();
                    setName(""); setUser(""); setEmail(""); setPassword(""); setConfirmPassword(""); setIsAdmin(false);
                }
            }
        ).catch(error => {
            console.log(error);
            loading(false);
        })
    }
  }

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography variant={"h5"} color="textSecondary" gutterBottom>
            Cadastrar novo usuário
        </Typography>
        <br></br>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12}  md={4}>
                <TextField 
                    fullWidth 
                    id="nome" 
                    label="Nome" 
                    value={name}
                    error={errorName.error}
                    helperText={errorName.error ? errorName.message : ""}
                    required
                    onChange={(e) => { setName(e.target.value); setErrorName(validRequired(e.target.value))}}
                    onBlur={(e) => {setErrorName(validRequired(e.target.value))}}
                    variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={12}  md={5}>
                <TextField 
                    fullWidth 
                    id="email" 
                    type="email"
                    label="E-mail" 
                    error={errorEmail.error}
                    helperText={errorEmail.error ? errorEmail.message : ""}
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={(e) => {setErrorEmail(validEmail(e.target.value))}}
                    variant="outlined" />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
                <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Administrador</InputLabel>
                    <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={isAdmin}
                    onChange={(e) => setIsAdmin(e.target.value)}
                    label="Administrador"
                    >
                    <MenuItem value={false}>Não</MenuItem>
                    <MenuItem value={true}>Sim</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={12}  md={4}>
                <TextField 
                    fullWidth 
                    id="user" 
                    type="text"
                    label="Usuário" 
                    error={errorUser.error}
                    helperText={errorUser.error ? errorUser.message : ""}
                    value={user}
                    required
                    onChange={(e) => setUser(e.target.value)}
                    onBlur={(e) => {  
                        setErrorUser(validMinLength(e.target.value, 3))
                    }}
                    variant="outlined" />
            </Grid>

            <Grid item xs={12} sm={12}  md={4}>
                <TextField 
                    fullWidth 
                    id="password" 
                    type="password"
                    label="Senha" 
                    error={errorPassword.error}
                    helperText={errorPassword.error ? errorPassword.message : ""}
                    value={password}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={(e) => {  
                        setErrorPassword(validMinLength(e.target.value, 6))
                    }}
                    variant="outlined" />
            </Grid>

            <Grid item xs={12} sm={12}  md={4}>
                <TextField 
                    fullWidth 
                    id="confirmPassword" 
                    type="password"
                    label="Confirmar Senha" 
                    error={errorConfirmPassword.error}
                    helperText={errorConfirmPassword.error ? errorConfirmPassword.message : ""}
                    value={confirmPassword}
                    required
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onBlur={(e) => {  
                        setErrorConfirmPassword(compareFields(password, e.target.value, "Confirmar Senha"))
                    }}
                    variant="outlined" />
            </Grid>
            
        </Grid>
        
      </CardContent>
      <CardActions style={{display: "flex", justifyContent: "center", justifyItems: "center"}}>
        <Button  onClick={() => {newUser()}} color={"primary"} variant={"outlined"}>Cadastrar</Button>
      </CardActions>
    </Card>
  );
}