import React, {useState} from 'react'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DropzoneWithoutDrag from '../../../components/DropZone';
import { importFicha } from '../../../services/FichaService';

export default function ImportarFicha(props) {

    const { open, setOpen, setOpenSweet, listClientes, setMesssageSweet, setCheck } = props;

    const [isLoading, setisLoading] = useState(false);
    const [file, setFile] = useState([]);

    const handleCancel = () => {
        setOpen(false);
    };

    const importaFicha = () => {
        setisLoading(true);
        importFicha(file).then(response => {
            if(response.data.status){
                listClientes();
                setCheck(false);
                setOpenSweet(true);
                setOpen(false);
                setisLoading(true);
                setMesssageSweet(response.data.data.message[0])
            }
        }).catch(error => {
            console.log(error);
            setisLoading(false);
        })
    }


    return (
        <Dialog
        disableBackdropClick
        maxWidth={"sm"}
        disableEscapeKeyDown
        aria-labelledby="confirmation-dialog-title"
        open={open}
        >
        <DialogTitle id="confirmation-dialog-title"><CloudUploadIcon /> Importar Ficha</DialogTitle>
        <DialogContent dividers>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DropzoneWithoutDrag setFile={setFile} />
                </Grid>
                <Grid item xs={12}>
                
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button variant="outlined" onClick={() => handleCancel()} autoFocus color="secondary">
                Cancelar
            </Button>
            <Button 
                disabled={
                    false
                }
                onClick={() => importaFicha()}
                variant="outlined" 
                color="primary">
                {
                    isLoading ? (<CircularProgress size={26}  />) : "Importar "
                }
            </Button>
        </DialogActions>
    </Dialog>
    
    )
}
