import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import AcordoIcon from '../../assets/icons/acordo.svg';
import CustomerIcon from '../../assets/icons/customer.svg';
import ReportIcon from '../../assets/icons/report.svg';
import DashboardIcon from '../../assets/icons/dashboard.svg';
import UsersIcon from '../../assets/icons/users.svg';
import EmprestimoIcon from '../../assets/icons/emprestimo.svg';

import GroupIcon from '@material-ui/icons/Group';
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUser } from "../../context/UserContext";

const structure = [
 
  { id: 0, label: "Meus Acordos", link: "/app/acordos", icon: AcordoIcon, show_only_admin: false },
  { id: 1, label: "Clientes", link: "/app/clients", icon: CustomerIcon, show_only_admin: false },
  { id: 2, label: "Usuários", link: "/app/users", icon: UsersIcon, show_only_admin: true},
  { id: 3, label: "Relatórios", link: "/app/reports", icon: ReportIcon, show_only_admin: false },
  {
    id: 4,
    label: "Dashboard", link: "/app/dashboard",
    icon: DashboardIcon,
    show_only_admin: true
  },
  {
    id: 4,
    label: "Emprestimos", link: "/app/emprestimos",
    icon: EmprestimoIcon,
    show_only_admin: true
  },
  
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  const {dataUser} = useUser();
  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map(item => { 
          return !item.show_only_admin ? (
            <SidebarLink
              key={item.id}
              location={location}
              isSidebarOpened={isSidebarOpened}
              {...item}
            />
          ) : dataUser.is_admin ? (
            <SidebarLink
              key={item.id}
              location={location}
              isSidebarOpened={isSidebarOpened}
              {...item}
            />
          ) : <></>
        })}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
