import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import { useTheme } from "@material-ui/styles";

export default function ApexLineChart({data}) {

  var theme = useTheme();
  const [series, setSeries] = useState([]);

  useEffect(() => {
    const newSerie = [];
    data.map(item => {
      newSerie.push({
        name: item.name,
        data: item.data
      })
    })
    setSeries(newSerie);
  }, [data])

  return (
    <ApexCharts
      options={themeOptions(theme, data)}
      series={series}
      type="area"
      height={350}
    />
  );
}

// ############################################################
function themeOptions(theme, data) {
  return {
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "date",
      days: data.days
    },
    tooltip: {
      x: {
        format: "MM/yy",
      },
    },
    fill: {
      colors: [theme.palette.primary.light, theme.palette.success.light],
    },
    colors: [theme.palette.primary.main, theme.palette.success.main],
    chart: {
      toolbar: {
        show: true,
      },
    },
    legend: {
      show: true,
    },
  };
}
