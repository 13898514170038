import api from './api';

export function getDashboardLineDaily(comp = null){
    return api.get(`${getURI()}/line-daily?comp=${comp}`);
}

export function dashboardUsersAcordos() {
    return api.get(`${getURI()}/users-acordos`);
}

export function dashboardLastMonthsAcordos() {
    return api.get(`${getURI()}/last-months`);
}

function getURI(){
    return 'dashboard';
}