import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
require('dotenv').config({
  path: process.env.NODE_ENV === 'development' ? '.env.development.local' : '.env',
});

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(
  async config => {
    config.headers = {
      Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`,
    };
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  async config => {
    const data = config.data;

    if (data.errors && !data.status) {
      const array = Object.keys(data.errors).map(function (chave) {
        return data.errors[chave];
      });
      array.map(item => {
        toast.error(item[0]);
      });
    }
    return config;
  },
  error => {
    const response = error.response;

    if (error.response !== undefined) {
      const status = response.status;

      if (status === 401) {
        if (response.data.type === 'session') {
          toast.warn('Sessão Expirada !');
          window.location.href = '/';
          localStorage.clear();
        }

        if (response.data.type === 'login') {
          toast.error('Usuário ou Senha Inválidos !');
        }
      }

      if (status === 403) {
        window.location.href = '/';
        localStorage.clear();
      }

      if (status === 422) {
        const array = Object.keys(response.data.errors).map(function (chave) {
          return response.data.errors[chave];
        });

        array.map(item => {
          toast.error(item[0]);
        });

        throw response.data;
      }

      if (status === 500) {
        toast.error('Erro interno no servidor, entre em contato com suporte! ');
      }

      if (status === 503) {
        window.location.href = '/maintence';
      }

      if (status === 429) {
      }
    } else {
      toast.error('Erro interno no servidor, entre em contato com suporte! ');
    }

    return Promise.reject(error);
  },
);

export default api;
