import Axios from 'axios';
import api from './api';

export function importFicha(file) {
  let formData = new FormData();

  formData.append('file', file);
  console.log('ENTROU AQUI');
  return Axios.post('ficha', formData, {
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`,
    },
    timeout: 300000,
  });
}

export function changeFicha(data) {
  return api.post('ficha-status', data);
}
