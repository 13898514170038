import React, {useState, useEffect} from 'react'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { listCliente } from '../../../services/ClienteService'
import { setAcordos } from '../../../services/AcordoService';
import { toast } from 'react-toastify';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function CadastraAcordo(props) {

    const { open, setopen, setDataCalendar, data_acordo, dataCalendar, dateSelected, listAcordos } = props;

    const [cliente, setCliente] = useState("");
    const [idContrato, setIdContrato] = useState();
    const [listClientes, setListClientes] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [anotacoes, setAnotacoes] = useState("");
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
       listCliente(setListClientes);
    }, [])

    
    const onEditorStateChange = (e) => {
        setEditorState(e);
        const convertedValue = convertToRaw(e.getCurrentContent());
        setAnotacoes(JSON.stringify(convertedValue));
    };

    const handleCancel = () => {
        setopen(false);
     };

    const saveAccord = () => {
        const divider = cliente.split("|");
        const data = {anotacoes, data_acordo, nome_cliente : cliente, id_contrato: idContrato};

        setisLoading(true);
        if(anotacoes == "" || cliente == ""){
            toast.error("Preencha todos os campos !");
            setisLoading(false);
        }else{
            setAcordos(data)
            .then(response => {
                if(response.data.status == true){
                    toast.success("Acordo cadastrado com sucesso !");
                }
                listAcordos();
                setCliente("");
                setisLoading(false);
                handleCancel();
            }).catch(error => {
                setisLoading(false);
                console.log(error);
            });
              
            setEditorState(EditorState.createEmpty());
        }
    }

    return (
        <Dialog
        disableBackdropClick
        maxWidth={"sm"}
        disableEscapeKeyDown
        aria-labelledby="confirmation-dialog-title"
        open={open}
        >
        <DialogTitle id="confirmation-dialog-title">Cadastrar novo acordo para o dia {dateSelected}</DialogTitle>
        <DialogContent dividers>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    
                <FormControl variant="outlined" fullWidth >
                <Autocomplete
                    id="free-solo-demo"
                    options={listClientes}
                    getOptionLabel={(item) => item.nome_cliente +' - '+ item.numero_documento}
                    onChange={(e, value) => {
                        setCliente(value.nome_cliente);
                        setIdContrato(value.id_contrato);
                    }}
                    renderInput={(params) => (
                        <TextField {...params} 
                        label="Digite o Cliente" 
                        margin="normal" 
                        variant="outlined" />
                    )}
                />
            
                </FormControl>
                </Grid>
                <Grid item xs={12}>
                <Editor 
                    editorState={editorState}
                    wrapperClassName="rich-editor demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={(e) => onEditorStateChange(e)}
                    placeholder=" Anotações.." />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button variant="outlined" onClick={() => handleCancel()} autoFocus color="secondary">
            Cancelar
            </Button>
            <Button 
                disabled={
                    false
                }
                onClick={() => saveAccord()}
                variant="outlined" 
                color="primary">
                {
                    isLoading ? (<CircularProgress size={26}  />) : "Salvar"
                }
            </Button>
        
        </DialogActions>
    </Dialog>
    
    )
}

/**
 *  <InputLabel id="demo-simple-select-outlined-label">Selecione o Cliente *</InputLabel>
                    <Select
                    required
                    fullWidth
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Selecione o Cliente *"
                    key={cliente}
                    value={cliente}
                    onChange={e => setCliente(e.target.value)}
                    >
                        {
                            listClientes.length === 0 ? (
                                <MenuItem value="">Nenhum cliente carregado !</MenuItem>
                            ) : ""
                        }
                    {
                      listClientes.map(item => (
                      <MenuItem value={item.id_contrato+'|'+item.nome_cliente+' - '+item.numero_documento}>{}</MenuItem>
                      ))
                    }
                    </Select>
 */
